"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_native_1 = require("react-native");

var autocomplete_item_style_1 = require("./pricing-system/autocomplete-item.style");

var autocomplete_style_1 = require("./pricing-system/autocomplete.style");

var base_text_style_1 = require("./pricing-system/base-text.style");

var busy_overlay_style_1 = require("./pricing-system/busy-overlay.style");

var button_style_1 = require("./pricing-system/button.style");

var dialog_style_1 = require("./pricing-system/dialog.style");

var error_panel_style_1 = require("./pricing-system/error-panel.style");

var field_style_1 = require("./pricing-system/field.style");

var heading_style_1 = require("./pricing-system/heading.style");

var hyperlink_style_1 = require("./pricing-system/hyperlink.style");

var message_panel_style_1 = require("./pricing-system/message-panel.style");

var native_label_style_1 = require("./pricing-system/native-label.style");

var output_value_style_1 = require("./pricing-system/output-value.style");

var page_buttons_style_1 = require("./pricing-system/page-buttons.style");

var page_style_1 = require("./pricing-system/page.style");

var panel_style_1 = require("./pricing-system/panel.style");

var picker_field_style_1 = require("./pricing-system/picker-field.style");

var static_text_style_1 = require("./pricing-system/static-text.style");

var table_style_1 = require("./pricing-system/table.style");

var tabs_style_1 = require("./pricing-system/tabs.style");

var theme_1 = require("./theme");

var generalContainerView = {
  flexDirection: 'row',
  flexGrow: 1,
  marginRight: 20,
  marginVertical: 10,
  maxWidth: 390,
  minWidth: 390
};
var pageScrollView = {
  backgroundColor: theme_1.GreyScale.lightest,
  height: '100%',
  overflow: 'scroll'
};
var labelView = {
  flexGrow: 1,
  paddingRight: 10
};
var checkboxGroupLabelView = {
  paddingRight: 10,
  width: 140
};
var checkboxGroupContainer = {
  flex: 0.75,
  flexDirection: 'row',
  flexGrow: 1,
  flexWrap: 'wrap',
  marginVertical: 10,
  width: 250
};
var checkboxGroupLabelText = {
  fontSize: 16,
  fontWeight: '400',
  marginTop: 10,
  textAlignVertical: 'center',
  width: '30vw'
};
var labelText = {
  fontSize: 16,
  fontWeight: '400',
  marginTop: 5,
  textAlignVertical: 'center'
};
var selectorText = {
  backgroundColor: theme_1.GreyScale.lightest,
  borderColor: theme_1.GreyScale.regular,
  borderRadius: 0,
  borderWidth: 2,
  height: 30,
  maxWidth: 250,
  width: '100%'
};
var titleText = {
  color: theme_1.PurpleScale.darker,
  fontSize: 36,
  fontWeight: '100',
  margin: 10,
  minWidth: '80%'
};
var flexRow = {
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  maxWidth: 1400,
  paddingHorizontal: 20
};
var ruleDetailsContainerView = {
  borderColor: theme_1.PurpleScale.darkest,
  borderWidth: 4,
  flex: 1,
  margin: 5,
  minWidth: 500,
  paddingHorizontal: 20,
  paddingVertical: 10
};
var checkboxContainerView = {
  flexDirection: 'row',
  marginBottom: 8,
  marginLeft: 8,
  width: 40
};
var checkboxContainer = {
  flexDirection: 'row',
  marginBottom: 8,
  marginLeft: 8,
  width: 40
};
var filterOptionCheckboxImageView = {
  flexGrow: 0,
  height: 20,
  marginLeft: 5,
  width: 20
};
var checkboxLabelText = {
  fontSize: 16,
  fontWeight: '500',
  textAlignVertical: 'center'
};
var textFieldOuterStyling = {
  borderColor: theme_1.GreyScale.regular,
  borderWidth: 2,
  flexGrow: 1,
  height: 30,
  maxWidth: 250,
  padding: 5,
  width: '100%'
};

var priceLookupTextInputStyle = __assign(__assign(__assign({}, base_text_style_1.baseTextStyle), textFieldOuterStyling), {
  backgroundColor: '#fff',
  borderColor: theme_1.GreyScale.light,
  maxWidth: 'auto'
});

var priceLookupTextInputErrorStyle = __assign(__assign({}, priceLookupTextInputStyle), {
  borderColor: theme_1.RedScale.regular,
  color: theme_1.RedScale.regular
});

var priceLookupTextInputDisabledStyle = __assign(__assign({}, priceLookupTextInputStyle), {
  backgroundColor: theme_1.GreyScale.lighter
});

var errorTextFieldOuterStyling = __assign(__assign({}, textFieldOuterStyling), {
  borderColor: theme_1.RedScale.regular,
  borderWidth: 3
});

var pricingStaticTextWrapper = __assign(__assign({}, textFieldOuterStyling), {
  borderWidth: 0
});

var inputContainerView = {
  borderColor: theme_1.PurpleScale.darkest,
  borderWidth: 4,
  height: 130,
  minWidth: 500,
  padding: 20
};
var pricingStaticTextStyle = {
  fontSize: 16,
  textAlignVertical: 'center'
};
var dataContainerView = {
  borderColor: theme_1.PurpleScale.darkest,
  borderTopWidth: 0,
  borderWidth: 4,
  height: 220,
  marginTop: 0,
  minWidth: 500
};
exports.pricingStyles = react_native_1.StyleSheet.create(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, autocomplete_item_style_1.autocompleteItemStyle), autocomplete_style_1.autocompleteStyle), busy_overlay_style_1.busyOverlayStyle), button_style_1.buttonStyle), {
  checkboxContainer: checkboxContainer,
  checkboxContainerView: checkboxContainerView,
  checkboxGroupContainer: checkboxGroupContainer,
  checkboxGroupLabelText: checkboxGroupLabelText,
  checkboxGroupLabelView: checkboxGroupLabelView,
  checkboxLabelText: checkboxLabelText,
  dataContainerView: dataContainerView
}), dialog_style_1.dialogStyle), error_panel_style_1.errorPanelStyle), {
  errorTextFieldOuterStyling: errorTextFieldOuterStyling
}), message_panel_style_1.messagePanelStyle), field_style_1.fieldStyle), {
  filterOptionCheckboxImageView: filterOptionCheckboxImageView,
  flexRow: flexRow,
  generalContainerView: generalContainerView
}), heading_style_1.headingStyle), hyperlink_style_1.hyperlinkStyle), {
  inputContainerView: inputContainerView,
  labelText: labelText,
  labelView: labelView
}), native_label_style_1.nativeLabelStyle), output_value_style_1.outputValueStyle), page_buttons_style_1.pageButtonsStyle), {
  pageScrollView: pageScrollView
}), page_style_1.pageStyle), panel_style_1.panelStyle), picker_field_style_1.pickerFieldStyle), {
  priceLookupTextInputDisabledStyle: priceLookupTextInputDisabledStyle,
  priceLookupTextInputErrorStyle: priceLookupTextInputErrorStyle,
  priceLookupTextInputStyle: priceLookupTextInputStyle,
  pricingStaticTextStyle: pricingStaticTextStyle,
  pricingStaticTextWrapper: pricingStaticTextWrapper,
  ruleDetailsContainerView: ruleDetailsContainerView,
  selectorText: selectorText
}), static_text_style_1.staticTextStyle), table_style_1.tableStyle), tabs_style_1.tabsStyle), {
  textFieldOuterStyling: textFieldOuterStyling,
  titleText: titleText
}));