"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var DrugNameFormatter = function () {
  function DrugNameFormatter() {}

  DrugNameFormatter.formatNames = function (name, genericName) {
    if (name === void 0) {
      name = '';
    }

    if (genericName === void 0) {
      genericName = '';
    }

    if (!name) {
      return genericName;
    }

    if (!genericName) {
      return name;
    }

    return name + " (" + genericName + ")";
  };

  return DrugNameFormatter;
}();

exports.DrugNameFormatter = DrugNameFormatter;