"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var constants_1 = require("./constants");

exports.QuantityFieldContent = {
  invalidInputMessage: constants_1.nonZeroWholeNumberRequired,
  label: 'Quantity'
};