"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_redux_1 = require("react-redux");

var group_picker_field_1 = require("./group-picker-field");

exports.mapStateToProps = function (state, ownProps) {
  return __assign({
    groups: state.priceLookup.pharmacyGroups
  }, ownProps);
};

exports.GroupPickerFieldConnected = react_redux_1.connect(exports.mapStateToProps)(group_picker_field_1.GroupPickerField);