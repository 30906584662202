"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var symbols_1 = require("../../utils/symbols");

exports.PriceLookupPageContent = {
  resetButton: 'Reset',
  submitButton: 'Submit',
  titleForAdmin: 'Price Lookup',
  titleForPharmacy: "PricingRx" + symbols_1.Symbols.tradeMark
};