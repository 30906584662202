"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var theme_1 = require("../theme");

var pageHeadingTextStyle = {
  marginTop: '.2em'
};
var pageNotificationTextStyle = {
  backgroundColor: theme_1.GreyScale.light,
  fontSize: '1.1rem',
  padding: '.2em',
  textAlign: 'center'
};
var pageViewStyle = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: '0.5rem'
};
var pageWrapperViewStyle = {
  backgroundColor: '#fff',
  height: '100%'
};
exports.pageStyle = {
  pageHeadingTextStyle: pageHeadingTextStyle,
  pageNotificationTextStyle: pageNotificationTextStyle,
  pageViewStyle: pageViewStyle,
  pageWrapperViewStyle: pageWrapperViewStyle
};