"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_redux_1 = require("react-redux");

var drug_get_async_action_1 = require("../../store/price-lookup/actions/drug-get-async.action");

var price_lookup_by_ndc_async_action_1 = require("../../store/price-lookup/actions/price-lookup-by-ndc-async.action");

var price_reset_action_1 = require("../../store/price-lookup/actions/price-reset.action");

var price_lookup_page_1 = require("./price-lookup-page");

exports.mapStateToProps = function (state) {
  var priceLookupState = state.priceLookup;
  return {
    currentGroup: priceLookupState.currentGroup,
    isAdmin: priceLookupState.authorization.isAdmin,
    isDrugSelected: !!priceLookupState.drugGetResult,
    isPriceLookupInProgress: priceLookupState.isPriceLookupInProgress
  };
};

exports.dispatchProps = {
  drugGet: drug_get_async_action_1.drugGetAsyncAction,
  priceLookupByNdc: price_lookup_by_ndc_async_action_1.priceLookupByNdcAsyncAction,
  priceReset: price_reset_action_1.priceResetAction
};
exports.PriceLookupPageConnected = react_redux_1.connect(exports.mapStateToProps, exports.dispatchProps)(price_lookup_page_1.PriceLookupPage);