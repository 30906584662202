"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var theme_1 = require("../theme");

var base_text_style_1 = require("./base-text.style");

var outputValueTextStyle = __assign(__assign({}, base_text_style_1.baseTextStyle), {
  color: theme_1.GreyScale.lightDark
});

var outputValuePlaceholderTextStyle = __assign(__assign({}, outputValueTextStyle), {
  color: theme_1.GreyScale.lighterDark,
  fontStyle: 'italic'
});

exports.outputValueAnimationDarkestColor = theme_1.PurpleScale.darkest;
exports.outputValueAnimationScaleMax = 1.2;
exports.outputValueStyle = {
  outputValuePlaceholderTextStyle: outputValuePlaceholderTextStyle,
  outputValueTextStyle: outputValueTextStyle
};