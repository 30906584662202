"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var price_lookup_action_keys_1 = require("./price-lookup-action-keys");

exports.drugSearchResponseAction = function (isSuccess, result) {
  return {
    payload: {
      isSuccess: isSuccess,
      result: result
    },
    type: price_lookup_action_keys_1.PriceLookupActionKeys.DRUG_SEARCH_RESPONSE
  };
};