"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_redux_1 = require("react-redux");

var pharmacy_pricing_reducer_actions_1 = require("../../../experiences/pharmacy-pricing-experience/store/pharmacy-pricing/pharmacy-pricing-reducer.actions");

var rule_details_text_field_1 = require("./rule-details-text-field");

var changeEffectiveDatePropertyAction = pharmacy_pricing_reducer_actions_1.pricingRuleDetailsSetDatePropertyActionBuilder('effectiveDate');
var textFieldActionProps = {
  onChangeText: changeEffectiveDatePropertyAction
};

var separateDateStringElements = function separateDateStringElements(dateElement) {
  return (dateElement.getMonth() + 1).toString() + '/' + dateElement.getDate().toString() + '/' + dateElement.getFullYear().toString();
};

var formatDateString = function formatDateString(state) {
  try {
    if (state.pharmacyPricing.selectedPricingRule.errors.effectiveDate) {
      return state.pharmacyPricing.selectedPricingRule.effectiveDate;
    }

    return state.pharmacyPricing.selectedPricingRule ? separateDateStringElements(state.pharmacyPricing.selectedPricingRule.effectiveDate) : undefined;
  } catch (error) {
    return separateDateStringElements(state.pharmacyPricing.selectedPharmacy.pricingRules.find(function (rule) {
      return rule.address === state.pharmacyPricing.selectedPricingRule.address;
    }).effectiveDate);
  }
};

var getError = function getError(state) {
  try {
    return state.pharmacyPricing.selectedPricingRule ? state.pharmacyPricing.selectedPricingRule.errors.effectiveDate : false;
  } catch (error) {
    return false;
  }
};

exports.mapStateToProps = function (state) {
  return __assign(__assign(__assign({}, state), textFieldActionProps), {
    hasError: getError(state),
    id: 'effectiveDate',
    label: 'Effective Date:',
    number: false,
    value: formatDateString(state)
  });
};

var actions = textFieldActionProps;
exports.ConnectedEffectiveDate = react_redux_1.connect(exports.mapStateToProps, actions)(rule_details_text_field_1.TextField);