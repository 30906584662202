// Copyright 2018 Prescryptive Health Inc
import { ExpectedFileKeys } from 'prescryptive-ux-common/lib/theming/assets';
import { ImageSourcePropType } from 'react-native';

// tslint:disable:no-var-requires
const headerBackButton = require('./images/header-back-button.png');
const filterButton = require('./images/filter.png');
const cvsPharmacy1Logo = require('./images/cvs-pharmacy1-logo.png');
const prescryptiveLogo = require('./images/prescryptive-logo.png');
const splashScreen = require('./images/splash-screen-background-image-with-logo.png');
const dropDownArrowButton = require('./images/down-arrow.png');
const checkCircleLogo = require('./images/check-circle.png');
const unCheckCircleLogo = require('./images/uncheck-circle.png');
const dropUpArrowButton = require('./images/up-arrow.png');
const closeRedButton = require('./images/close-red-button.png');
const closeWhiteButton = require('./images/close-white-button.png');
const checkBoxButton = require('./images/checkbox-checked.png');
const unCheckBoxButton = require('./images/checkbox-default.png');
const closeGreyButton = require('./images/close-grey-button.png');
const headerPrescryptiveTrademarkLogo = require('./images/header-prescryptive-trademark-logo.svg');
const drawerhamburger = require('./images/drawer-hamburger.png');
const rightArrowButton = require('./images/right-arrow.png');
const iconHappyMeds = require('./images/icon-happy-meds.png');
const iconForm = require('./images/icon-form.png');
const iconBlankBottle = require('./images/icon-blank-bottle.png');
const iconRx = require('./images/icon-rx.png');
const forwardArrowButton = require('../assets/images/arrow-right.png');
const backwardArrowButton = require('../assets/images/arrow-left.png');
const sideMenuSupportIcon = require('../assets/images/ic-support.svg');
const sideMenuMembersIcon = require('../assets/images/ic-members.svg');
const sideMenuAlertsIcon = require('../assets/images/ic-alert.svg');
const sideMenuBenefitIcon = require('../assets/images/ic-benefits.svg');
const sideMenuCloseMenuIcon = require('../assets/images/ic-cross.svg');
const sideMenuAvatarIcon = require('../assets/images/ic-profile.svg');
const feedbackThumbsUp = require('../assets/images/feedback-thumbs-up.svg');
const feedbackThumbsDown = require('../assets/images/feedback-thumbs-down.svg');
const selectedFeedbackThumbsUp = require('../assets/images/selected-feedback-thumbs-up.svg');
const selectedFeedbackThumbsDown = require('../assets/images/selected-feedback-thumbs-down.svg');
const iconCheckCircleRegular = require('../assets/images/check-circle-regular.png');
const errorCircle = require('../assets/images/error-circle.png');
const yellowBang = require('../assets/images/yellowBang.png');
const groupIcon = require('../assets/images/group.svg');
require('react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf');

export const LocalFileSourcesMap = new Map<
  ExpectedFileKeys,
  ImageSourcePropType
>();
LocalFileSourcesMap.set('cvsPharmacy1Logo', cvsPharmacy1Logo);
LocalFileSourcesMap.set('downArrow', filterButton);
LocalFileSourcesMap.set('leftArrow', headerBackButton);
LocalFileSourcesMap.set('prescryptiveLogo', prescryptiveLogo);
LocalFileSourcesMap.set('splashScreen', splashScreen);
LocalFileSourcesMap.set('dropDownArrowButton', dropDownArrowButton);
LocalFileSourcesMap.set('checkCircleLogo', checkCircleLogo);
LocalFileSourcesMap.set('unCheckCircleLogo', unCheckCircleLogo);
LocalFileSourcesMap.set('dropUpArrowButton', dropUpArrowButton);
LocalFileSourcesMap.set('closeRedButton', closeRedButton);
LocalFileSourcesMap.set('closeWhiteButton', closeWhiteButton);
LocalFileSourcesMap.set('checkBoxButton', checkBoxButton);
LocalFileSourcesMap.set('unCheckBoxButton', unCheckBoxButton);
LocalFileSourcesMap.set('closeGreyButton', closeGreyButton);
LocalFileSourcesMap.set(
  'headerPrescryptiveTrademarkLogo',
  headerPrescryptiveTrademarkLogo
);
LocalFileSourcesMap.set('drawerhamburger', drawerhamburger);
LocalFileSourcesMap.set('rightArrowButton', rightArrowButton);
LocalFileSourcesMap.set('iconHappyMeds', iconHappyMeds);
LocalFileSourcesMap.set('iconForm', iconForm);
LocalFileSourcesMap.set('iconBlankBottle', iconBlankBottle);
LocalFileSourcesMap.set('iconRx', iconRx);
LocalFileSourcesMap.set('forwardArrowButton', forwardArrowButton);
LocalFileSourcesMap.set('backwardArrowButton', backwardArrowButton);
LocalFileSourcesMap.set('sideMenuSupportIcon', sideMenuSupportIcon);
LocalFileSourcesMap.set('sideMenuMembersIcon', sideMenuMembersIcon);
LocalFileSourcesMap.set('sideMenuAlertsIcon', sideMenuAlertsIcon);
LocalFileSourcesMap.set('sideMenuBenefitIcon', sideMenuBenefitIcon);
LocalFileSourcesMap.set('sideMenuCloseMenuIcon', sideMenuCloseMenuIcon);
LocalFileSourcesMap.set('sideMenuAvatarIcon', sideMenuAvatarIcon);
LocalFileSourcesMap.set('feedbackThumbsUp', feedbackThumbsUp);
LocalFileSourcesMap.set('feedbackThumbsDown', feedbackThumbsDown);
LocalFileSourcesMap.set('selectedFeedbackThumbsUp', selectedFeedbackThumbsUp);
LocalFileSourcesMap.set(
  'selectedFeedbackThumbsDown',
  selectedFeedbackThumbsDown
);
LocalFileSourcesMap.set('iconCheckCircleRegular', iconCheckCircleRegular);
LocalFileSourcesMap.set('errorCircle', errorCircle);
LocalFileSourcesMap.set('yellowBang', yellowBang);
LocalFileSourcesMap.set('groupIcon', groupIcon);
