"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var theme_1 = require("../theme");

exports.baseTextStyle = {
  fontFamily: theme_1.FontFamily.default,
  fontSize: theme_1.FontSize.smallest
};