"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var base_text_style_1 = require("./base-text.style");

var autocompleteItemTextStyle = __assign(__assign({}, base_text_style_1.baseTextStyle), {
  paddingBottom: '.2em',
  paddingLeft: '.4em',
  paddingRight: '.4em',
  paddingTop: '.2em'
});

exports.autocompleteItemBackground = 'inherit';
exports.autocompleteItemBackgroundAlternate = '#f6f6f6';
exports.autocompleteItemStyle = {
  autocompleteItemTextStyle: autocompleteItemTextStyle
};