"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HttpStatusCodes = {
  ACCEPTED: 202,
  BAD_REQUEST: 400,
  FORBIDDEN_ERROR: 403,
  INTERNAL_SERVER_ERROR: 500,
  NOT_FOUND: 404,
  SERVER_DATA_ERROR: 551,
  SERVICE_UNAVAILABLE: 503,
  SUCCESS: 200,
  TOO_MANY_REQUESTS: 429,
  UNAUTHORIZED_REQUEST: 401
};
exports.TwilioErrorCodes = {
  INVALID_PARAMETER: 60200,
  MAX_SEND_ATTEMPTS_REACHED: 60203,
  MAX_VERIFICATION_CHECK_ATTEMPTS_REACHED: 60202,
  PERMISSION_DENIED: 20003,
  UNSUPPORTED_LANDLINE_NUMBER: 60205
};
exports.InternalErrorCode = {
  INVALID_ACCOUNT_TOKEN: 2008,
  INVALID_DEVICE_TOKEN: 2005,
  SHOW_FORGET_PIN: 2007,
  TOKEN_EXPIRED: 2004,
  UNAUTHORIZED_ACCESS_PHONE_NUMBER_MISMATCHED: 1001
};
exports.InternalResponseCode = {
  INVALID_PHONE_NUMBER: 1003,
  PHONE_NUMBER_MISSING: 1002,
  REQUIRE_USER_REGISTRATION: 2003,
  REQUIRE_USER_SET_PIN: 2001,
  REQUIRE_USER_SHOW_PIN_FEATURE_WELCOME_SCREEN: 2011,
  REQUIRE_USER_VERIFY_PIN: 2002,
  SHOULD_SHOW_PHONE_NUMBER_MISSING: 2006,
  USE_ANOTHER_PIN: 2009
};