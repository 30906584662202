"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_native_router_flux_1 = require("react-native-router-flux");

var navigation_reducer_helper_1 = require("./navigation-reducer.helper");

exports.DefaultSceneNavigationState = {
  allowBack: false,
  currentScene: 'unknown'
};

exports.sceneNavigationReducer = function (state, _a) {
  if (state === void 0) {
    state = exports.DefaultSceneNavigationState;
  }

  var type = _a.type;

  switch (type) {
    case react_native_router_flux_1.ActionConst.FOCUS:
      return __assign(__assign({}, state), {
        allowBack: navigation_reducer_helper_1.allowNavigationBack(react_native_router_flux_1.Actions.currentScene),
        currentScene: react_native_router_flux_1.Actions.currentScene
      });

    default:
      if (state.allowBack) {
        return __assign(__assign({}, state), {
          allowBack: navigation_reducer_helper_1.allowNavigationBack(react_native_router_flux_1.Actions.currentScene)
        });
      }

  }

  return state;
};