"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[typeof Symbol === "function" ? Symbol.iterator : "@@iterator"] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var error_codes_1 = require("../../../../errors/error-codes");

var constants_1 = require("../../../../theming/constants");

var api_errors_1 = require("../../../../theming/pricing-system/api-errors");

var api_helper_1 = require("../../../../utils/api.helper");

var price_lookup_response_helper_1 = require("./price-lookup-response-helper");

function priceLookupByNdc(config, subscriptionId, groupPlanCode, pharmacyId, ndc, daysSupply, quantity, authToken, fillDate) {
  if (fillDate === void 0) {
    fillDate = new Date();
  }

  return __awaiter(this, void 0, void 0, function () {
    var url, headers, body, response, errorMessage;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (!authToken) {
            throw Error(constants_1.ErrorConstants.errorMissingProperty('authToken'));
          }

          url = api_helper_1.buildUrl(config, 'priceLookup', {});
          headers = __assign(__assign({}, api_helper_1.buildAuthHeaders(authToken)), {
            'Ocp-Apim-Subscription-Key': subscriptionId
          });
          body = {
            daysSupply: daysSupply,
            fillDate: formatAsUtcDate(fillDate),
            groupPlanCode: groupPlanCode,
            nationalDrugCode: ndc,
            pharmacyId: pharmacyId,
            quantity: quantity
          };
          return [4, api_helper_1.call(url, body, 'POST', headers)];

        case 1:
          response = _a.sent();
          if (!response.ok) return [3, 3];
          return [4, price_lookup_response_helper_1.PriceLookupResponseHelper.getResultFromResponse(response)];

        case 2:
          return [2, _a.sent()];

        case 3:
          if (response.status === error_codes_1.HttpStatusCodes.NOT_FOUND) {
            throw Error(api_errors_1.ApiErrors.resourceNotFound(url));
          }

          return [4, price_lookup_response_helper_1.PriceLookupResponseHelper.getErrorMessageFromResponse(response)];

        case 4:
          errorMessage = _a.sent();
          throw Error(api_errors_1.ApiErrors.priceLookup(errorMessage));
      }
    });
  });
}

exports.priceLookupByNdc = priceLookupByNdc;

function formatAsUtcDate(date) {
  var formattedUtcDateAndTime = date.toISOString();
  return formattedUtcDateAndTime.slice(0, 10);
}