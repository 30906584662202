"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var pageButtonsViewStyle = {
  flexDirection: 'row',
  justifyContent: 'center',
  marginBottom: '.5rem',
  marginTop: '1rem'
};
exports.pageButtonsStyle = {
  pageButtonsViewStyle: pageButtonsViewStyle
};