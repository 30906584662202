"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PharmacyEditDialogContent = {
  closeButtonText: 'Close',
  passwordGenerateButtonText: 'Generate Password',
  passwordLabel: 'Password',
  pharmacyNameLabel: 'Pharmacy',
  pharmacyNcpdpLabel: 'NCPDP',
  title: 'Change Pharmacy Password',
  userLoginLabel: 'User Login'
};