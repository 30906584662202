"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ErrorContext;

(function (ErrorContext) {
  ErrorContext[ErrorContext["boundary"] = 1] = "boundary";
  ErrorContext[ErrorContext["drugGet"] = 2] = "drugGet";
  ErrorContext[ErrorContext["drugSearch"] = 3] = "drugSearch";
  ErrorContext[ErrorContext["getPharmacies"] = 4] = "getPharmacies";
  ErrorContext[ErrorContext["login"] = 5] = "login";
  ErrorContext[ErrorContext["logout"] = 6] = "logout";
  ErrorContext[ErrorContext["passwordGenerate"] = 7] = "passwordGenerate";
  ErrorContext[ErrorContext["priceLookup"] = 8] = "priceLookup";
})(ErrorContext = exports.ErrorContext || (exports.ErrorContext = {}));