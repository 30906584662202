"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var rule_details_mock_1 = require("./rule-details.mock");

exports.costco = {
  address: '0',
  name: 'Costco Pharmacy',
  ncpdp: 'xx',
  pricingRules: rule_details_mock_1.mockRuleList
};
exports.bartells = {
  address: '1',
  name: "Bartell's Pharmacy",
  ncpdp: 'xx',
  pricingRules: []
};
exports.mockPharmacyGroupList = [exports.costco, exports.bartells];