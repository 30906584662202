"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var MoneyFormatter = function () {
  function MoneyFormatter() {}

  MoneyFormatter.format = function (amountInDollars) {
    if (amountInDollars === undefined) {
      return '';
    }

    var formatter = new Intl.NumberFormat('en-US', {
      currency: 'USD',
      style: 'currency'
    });
    return formatter.format(amountInDollars);
  };

  return MoneyFormatter;
}();

exports.MoneyFormatter = MoneyFormatter;