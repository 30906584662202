// Copyright 2018 Prescryptive Health, inc

export const loadConfig = <TKeys extends { [key: string]: string }>(
  config: { [key in keyof TKeys]: string },
  map: Map<string, string>,
  document: Document = window.document
) => {
  Object.keys(config).forEach(key => {
    const envKey = map.get(key);
    if (envKey) {
      const value = getMetaEnvironmentVariable(envKey, config[key], document);
      config[key as keyof TKeys] = value;
    }
  });
};

export const getMetaEnvironmentVariable = (
  name: string,
  fallback: string,
  document: Document
) => {
  const selector = document.querySelector(`meta[property="env:${name}"]`);
  if (selector) {
    const val = selector.getAttribute('content');
    if (val && val !== `$\{${name}}`) {
      return val;
    }
  }
  const build = process.env[`REACT_APP_CONFIG_${name}`];
  return neitherNullNorEmpty(build, fallback);
};

export const neitherNullNorEmpty = (
  text: string | undefined,
  defaultText: string
) => {
  return text && text.trim().length > 0 ? text : defaultText;
};
