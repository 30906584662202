"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var price_lookup_action_keys_1 = require("./actions/price-lookup-action-keys");

var DefaultPriceLookupState = {
  authorization: {},
  drugSearchRequestCount: 0
};

exports.priceLookupReducer = function (state, action) {
  if (state === void 0) {
    state = DefaultPriceLookupState;
  }

  switch (action.type) {
    case price_lookup_action_keys_1.PriceLookupActionKeys.DRUG_GET_RESPONSE:
      return drugGetResponseReducer(state, action);

    case price_lookup_action_keys_1.PriceLookupActionKeys.DRUG_SEARCH_IN_PROGRESS:
      return drugSearchInProgressReducer(state);

    case price_lookup_action_keys_1.PriceLookupActionKeys.DRUG_SEARCH_RESPONSE:
      return drugSearchResponseReducer(state, action);

    case price_lookup_action_keys_1.PriceLookupActionKeys.GET_ALL_DRUG_FORMS_RESPONSE:
      return getAllDrugFormsResponseReducer(state, action);

    case price_lookup_action_keys_1.PriceLookupActionKeys.GET_ALL_PACKAGE_TYPES_RESPONSE:
      return getAllPackageTypesResponseReducer(state, action);

    case price_lookup_action_keys_1.PriceLookupActionKeys.GET_PHARMACY_GROUPS_RESPONSE:
      return getPharmacyGroupsResponseReducer(state, action);

    case price_lookup_action_keys_1.PriceLookupActionKeys.GET_PHARMACIES_RESPONSE:
      return getPharmaciesResponseReducer(state, action);

    case price_lookup_action_keys_1.PriceLookupActionKeys.PRICE_LOOKUP_RESPONSE:
      return priceLookupResponseReducer(state, action);

    case price_lookup_action_keys_1.PriceLookupActionKeys.PRICE_LOOKUP_IN_PROGRESS:
      return priceLookupInProgressReducer(state, action);

    case price_lookup_action_keys_1.PriceLookupActionKeys.PRICE_RESET:
      return priceResetReducer(state);

    case price_lookup_action_keys_1.PriceLookupActionKeys.WAIT_COMPLETE:
      return waitCompleteReducer(state, action);

    case price_lookup_action_keys_1.PriceLookupActionKeys.LOGIN_RESPONSE:
      return loginResponseReducer(state, action);

    case price_lookup_action_keys_1.PriceLookupActionKeys.LOGIN_INCOMPLETE_ERROR_SHOW:
      return loginIncompleteErrorShowReducer(state, action);

    case price_lookup_action_keys_1.PriceLookupActionKeys.LOGOUT_RESPONSE:
      return logoutResponseReducer(state);

    case price_lookup_action_keys_1.PriceLookupActionKeys.PHARMACY_GROUP_SELECT:
      return pharmacyGroupSelectReducer(state, action);

    case price_lookup_action_keys_1.PriceLookupActionKeys.PASSWORD_GENERATE_RESPONSE:
      return passwordGenerateResponseReducer(state, action);

    case price_lookup_action_keys_1.PriceLookupActionKeys.PASSWORD_CLEAR:
      return passwordClearReducer(state);
  }

  return state;
};

var drugGetResponseReducer = function drugGetResponseReducer(state, action) {
  var updatedState = __assign(__assign({}, state), {
    drugGetResult: action.payload
  });

  return updatedState;
};

var drugSearchInProgressReducer = function drugSearchInProgressReducer(state) {
  var updatedState = __assign(__assign({}, state), {
    drugSearchRequestCount: state.drugSearchRequestCount + 1
  });

  return updatedState;
};

var drugSearchResponseReducer = function drugSearchResponseReducer(state, action) {
  var drugSearchRequestCount = state.drugSearchRequestCount - 1;
  var drugSearchResult = drugSearchRequestCount === 0 ? action.payload.result : state.drugSearchResult;

  var updatedState = __assign(__assign({}, state), {
    drugSearchRequestCount: drugSearchRequestCount,
    drugSearchResult: drugSearchResult
  });

  return updatedState;
};

var getAllDrugFormsResponseReducer = function getAllDrugFormsResponseReducer(state, action) {
  var updatedState = __assign(__assign({}, state), {
    drugForms: action.payload.forms
  });

  return updatedState;
};

var getAllPackageTypesResponseReducer = function getAllPackageTypesResponseReducer(state, action) {
  var updatedState = __assign(__assign({}, state), {
    packageTypes: action.payload.types
  });

  return updatedState;
};

var getPharmacyGroupsResponseReducer = function getPharmacyGroupsResponseReducer(state, action) {
  var updatedState = __assign(__assign({}, state), {
    pharmacyGroups: action.payload.groups
  });

  return updatedState;
};

var getPharmaciesResponseReducer = function getPharmaciesResponseReducer(state, action) {
  var updatedState = __assign(__assign({}, state), {
    doPharmaciesRefresh: false,
    pharmacies: action.payload.pharmacies
  });

  return updatedState;
};

var priceLookupResponseReducer = function priceLookupResponseReducer(state, action) {
  var updatedState = __assign(__assign({}, state), {
    isWaitingForTransactionDetails: true,
    priceLookupResult: __assign({}, action.payload)
  });

  return updatedState;
};

var priceLookupInProgressReducer = function priceLookupInProgressReducer(state, action) {
  var updatedState = __assign(__assign({}, state), {
    isPriceLookupInProgress: action.payload
  });

  return updatedState;
};

var priceResetReducer = function priceResetReducer(state) {
  var updatedState = __assign(__assign({}, state), {
    priceLookupResult: undefined
  });

  return updatedState;
};

var waitCompleteReducer = function waitCompleteReducer(state, action) {
  var updatedState = __assign({}, state);

  switch (action.payload) {
    case price_lookup_action_keys_1.PriceLookupWaitKeys.TRANSACTION_DETAILS:
      updatedState.isWaitingForTransactionDetails = false;
      break;
  }

  return updatedState;
};

var loginResponseReducer = function loginResponseReducer(state, action) {
  var updatedState = __assign(__assign({}, state), {
    authorization: __assign({}, action.payload)
  });

  return updatedState;
};

var loginIncompleteErrorShowReducer = function loginIncompleteErrorShowReducer(state, action) {
  var updatedState = __assign({}, state);

  updatedState.authorization.isLoginIncomplete = true;
  updatedState.authorization.accountName = action.payload;
  return updatedState;
};

var logoutResponseReducer = function logoutResponseReducer(state) {
  var updatedState = __assign({}, state);

  updatedState.authorization.isLoginIncomplete = false;
  return updatedState;
};

var pharmacyGroupSelectReducer = function pharmacyGroupSelectReducer(state, action) {
  var updatedState = __assign(__assign({}, state), {
    currentGroup: action.payload,
    doPharmaciesRefresh: true
  });

  return updatedState;
};

var passwordGenerateResponseReducer = function passwordGenerateResponseReducer(state, action) {
  var updatedState = __assign(__assign({}, state), {
    pharmacyPassword: action.payload
  });

  return updatedState;
};

var passwordClearReducer = function passwordClearReducer(state) {
  var updatedState = __assign(__assign({}, state), {
    pharmacyPassword: undefined
  });

  return updatedState;
};