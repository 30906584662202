"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_redux_1 = require("react-redux");

var pharmacy_pricing_reducer_actions_1 = require("../../../../experiences/pharmacy-pricing-experience/store/pharmacy-pricing/pharmacy-pricing-reducer.actions");

var save_button_1 = require("./save-button");

var onPress = function onPress() {
  return pharmacy_pricing_reducer_actions_1.pricingRuleUpdateStartAction();
};

exports.saveButtonActionProps = {
  onPress: onPress
};

exports.mapStateToProps = function (state) {
  var isActiveCheck = function isActiveCheck() {
    var errors = Object.values(state.pharmacyPricing.selectedPricingRule.errors).find(function (val) {
      return val === true;
    });

    if (errors) {
      return false;
    }

    if (JSON.stringify(state.pharmacyPricing.selectedPricingRule) === JSON.stringify(state.pharmacyPricing.selectedPharmacy.pricingRules.find(function (r) {
      return r.address === state.pharmacyPricing.selectedPricingRule.address;
    }))) {
      return false;
    }

    return true;
  };

  return __assign(__assign({}, state), {
    isActive: isActiveCheck()
  });
};

exports.actions = exports.saveButtonActionProps;
exports.ConnectedSaveButton = react_redux_1.connect(exports.mapStateToProps, exports.actions)(save_button_1.SaveButton);