"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var busy_actions_1 = require("./busy.actions");

exports.busyReducer = function (state, action) {
  if (state === void 0) {
    state = {};
  }

  switch (action.type) {
    case busy_actions_1.BusyActionKeys.BUSY_SHOW:
      return busyShowActionReducer(state, action);
  }

  return state;
};

var busyShowActionReducer = function busyShowActionReducer(state, action) {
  var updatedState = __assign(__assign({}, state), {
    showBusy: action.payload
  });

  return updatedState;
};