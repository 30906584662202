// Copyright 2020 Prescryptive Health, inc

import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export class TelemetryService {
  private readonly instrumentationKey: string;
  private readonly browserHistory: object;
  private readonly reactPlugIn: ReactPlugin;
  private initializedAppInsights?: ApplicationInsights;

  constructor(
    instrumentationKey: string,
    browserHistory: object,
    reactPlugIn: ReactPlugin
  ) {
    if (!instrumentationKey) {
      throw Error('Instrumentation key not provided!');
    }
    this.instrumentationKey = instrumentationKey;

    this.browserHistory = browserHistory;
    this.reactPlugIn = reactPlugIn;
  }

  public trackException = (error: Error) => {
    this.appInsights.trackException({ exception: error });
  };

  public trackEvent = (actionType: string, payload: string | object) => {
    const properties =
      typeof payload === 'string' ? { message: payload } : payload;

    this.appInsights.trackEvent({
      name: actionType,
      properties,
    });
  };

  public get appInsights(): ApplicationInsights {
    if (!this.initializedAppInsights) {
      this.initializedAppInsights = this.initialize();
    }

    return this.initializedAppInsights;
  }

  private initialize(): ApplicationInsights {
    const appInsights = new ApplicationInsights({
      config: {
        disableAjaxTracking: false, // This is needed so that AAD login requests are tracked.
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        extensionConfig: {
          [this.reactPlugIn.identifier]: {
            history: this.browserHistory,
          },
        },
        extensions: [this.reactPlugIn],
        instrumentationKey: this.instrumentationKey,
        maxBatchInterval: 0,
      },
    });

    appInsights.loadAppInsights();
    return appInsights;
  }
}
