"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var constants_1 = require("../constants");

exports.ApiErrors = {
  drugGet: function drugGet(errorMessage) {
    return "Error attempting to get selected drug's details -- " + errorMessage;
  },
  drugSearch: function drugSearch(errorMessage) {
    return "Error attempting to search for matching drugs -- " + errorMessage;
  },
  getAllDrugForms: function getAllDrugForms(errorMessage) {
    return "Error getting drug forms -- " + errorMessage;
  },
  getAllPackageTypes: function getAllPackageTypes(errorMessage) {
    return "Error getting package types -- " + errorMessage;
  },
  getPharmacies: function getPharmacies(errorMessage, groupOid) {
    return "Error getting pharmacies for group with id '" + groupOid + "' -- " + errorMessage;
  },
  getPharmacyGroup: function getPharmacyGroup(errorMessage) {
    return "Error getting pharmacy group -- " + errorMessage;
  },
  getPharmacyGroups: function getPharmacyGroups(errorMessage) {
    return "Error getting pharmacy groups -- " + errorMessage;
  },
  login: function login(errorCode) {
    return "Error logging in -- " + errorCode;
  },
  passwordGenerate: function passwordGenerate(errorMessage, userId) {
    return "Couldn't generate password for user '" + userId + "' -- " + errorMessage;
  },
  priceLookup: function priceLookup(errorMessage) {
    return "Error getting price -- " + errorMessage;
  },
  resourceNotFound: constants_1.ErrorConstants.errorUrlNotFound
};