"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AutocompleteContent = {
  inputInvalid: 'Please specify a valid value.',
  moreItemsFound: function moreItemsFound(remainingItems) {
    return "... " + remainingItems + " more item(s) not shown ...";
  },
  noItemsFound: function noItemsFound(filter) {
    return "No items found matching \"" + filter + "\".";
  },
  searching: 'Searching...',
  specifyMinChars: function specifyMinChars(minChars) {
    return "Please specify a minimum of " + minChars + " characters.";
  }
};