// Copyright 2018 Prescryptive Health, inc

import { PricingExperienceFeatures } from 'prescryptive-ux-common/lib/experiences/pharmacy-pricing-experience/pricing-experience-features';
import {
  applyProcessSwitches,
  applyQuerySwitches,
} from 'prescryptive-ux-common/lib/utils/features.helper';

export function initializeFeatureSwitches() {
  applyProcessSwitches(PricingExperienceFeatures, process.env);
  applyQuerySwitches(PricingExperienceFeatures, location.search);
}
