// Copyright 2018 Prescryptive Health, inc

import {
  InMemoryState,
  ISettings,
  PricingExperienceSettings,
} from 'prescryptive-ux-common/lib/experiences/pharmacy-pricing-experience/pricing-experience-settings';
import { settingsStorage } from './settings-storage';

export const getLocalStorage = async (): Promise<ISettings> =>
  JSON.parse(settingsStorage.getItem('appSettings')!);

export const setLocalStorage = async (settings: ISettings) =>
  await settingsStorage.setItem('appSettings', JSON.stringify(settings));

export const storageAvailable = async () => {
  try {
    const storageTest = 'storageTestValue';
    await settingsStorage.setItem(storageTest, storageTest);
    await settingsStorage.removeItem(storageTest);
    return true;
  } catch (e) {
    return (
      (e instanceof DOMException &&
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        (settingsStorage && settingsStorage.length !== 0)) ||
      false
    );
  }
};

export async function initializeLocalStorage() {
  if (await storageAvailable()) {
    let initialState = await getLocalStorage();
    if (!initialState) {
      initialState = InMemoryState.settings;
      await PricingExperienceSettings.update(initialState);
    }
    PricingExperienceSettings.initialState = initialState;
    PricingExperienceSettings.current = getLocalStorage;
    PricingExperienceSettings.update = setLocalStorage;
  } else {
    throw Error('localStorage is required');
  }
}
