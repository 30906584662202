"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var price_lookup_action_keys_1 = require("./price-lookup-action-keys");

exports.loginIncompleteErrorShowAction = function (accountName) {
  return {
    payload: accountName,
    type: price_lookup_action_keys_1.PriceLookupActionKeys.LOGIN_INCOMPLETE_ERROR_SHOW
  };
};