"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_navigation_redux_helpers_1 = require("react-navigation-redux-helpers");

var navigation_reducer_middleware_1 = require("../store/navigation/navigation-reducer.middleware");

var pricing_experience_navigator_1 = require("./pricing-experience-navigator");

exports.ConnectedPricingExperienceNavigator = react_navigation_redux_helpers_1.reduxifyNavigator(pricing_experience_navigator_1.PricingExperienceNavigator, navigation_reducer_middleware_1.navigationMiddlewareKey);