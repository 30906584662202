"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Errors = {
  accountConfiguration: 'Account is incorrectly configured.',
  loginInProgress: 'Another login is already in progress. Please close all login windows and try again.',
  loginInProgressTitle: 'Login Already in Progress',
  noAuthenticationProvider: 'No authentication provider defined!',
  noCurrentGroup: 'Unable to determine current pharmacy group!'
};