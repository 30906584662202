"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_redux_1 = require("react-redux");

var pharmacy_pricing_reducer_actions_1 = require("../../../experiences/pharmacy-pricing-experience/store/pharmacy-pricing/pharmacy-pricing-reducer.actions");

var rule_details_text_field_1 = require("./rule-details-text-field");

var changeRuleNamePropertyAction = pharmacy_pricing_reducer_actions_1.pricingRuleDetailsSetStringPropertyActionBuilder('ruleName');
var textFieldActionProps = {
  onChangeText: changeRuleNamePropertyAction
};

exports.mapStateToProps = function (state) {
  return __assign(__assign(__assign({}, state), textFieldActionProps), {
    id: 'ruleName',
    label: 'Rule Name:',
    value: state.pharmacyPricing.selectedPricingRule ? state.pharmacyPricing.selectedPricingRule.ruleName : ''
  });
};

var actions = textFieldActionProps;
exports.ConnectedRuleName = react_redux_1.connect(exports.mapStateToProps, actions)(rule_details_text_field_1.TextField);