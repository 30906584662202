"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var pricing_experience_config_1 = require("../../pricing-experience-config");

var ConfigStateActionKeys;

(function (ConfigStateActionKeys) {})(ConfigStateActionKeys = exports.ConfigStateActionKeys || (exports.ConfigStateActionKeys = {}));

exports.configStateReducer = function (state) {
  if (state === void 0) {
    state = pricing_experience_config_1.PricingExperienceConfig;
  }

  return state;
};