"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var price_lookup_action_keys_1 = require("./price-lookup-action-keys");

exports.drugSearchInProgressAction = function () {
  return {
    type: price_lookup_action_keys_1.PriceLookupActionKeys.DRUG_SEARCH_IN_PROGRESS
  };
};