// Copyright 2018 Prescryptive Health Inc
(window as any).__DEV__ = process.env.NODE_ENV === 'development';
import { PricingExperienceNavigation } from 'prescryptive-ux-common/lib/experiences/pharmacy-pricing-experience/pricing-experience-navigation';
import { initializeImageAssets } from 'prescryptive-ux-common/lib/utils/assets.helper';
import { AppRegistry } from 'react-native';
import { initializeLocalStorage } from './settings-config';

import App from './App';
import { LocalFileSourcesMap } from './assets/local-assets';
import { initializeAuthentication } from './initialize-authentication';
import { initializeConfig } from './initialize-config';
import { initializeFeatureSwitches } from './initialize-feature-switches';
import { initializeTelemetry } from './initialize-telemetry';

initializeConfig();
const initialize = Promise.all([
  initializeLocalStorage(),
  initializeFeatureSwitches(),
  initializeImageAssets(LocalFileSourcesMap),
  initializeTelemetry(),
  initializeAuthentication(),
]);

export const noBack = () => {
  window.history.forward();
};

window.onpageshow = (event: { persisted: boolean }) => {
  window.history.pushState('', '');
  if (event.persisted) {
    noBack();
  }
};

window.onpopstate = (event: Event) => {
  PricingExperienceNavigation.handleBack();
  noBack();
  event.preventDefault();
  event.stopImmediatePropagation();
  event.stopPropagation();
  return false;
};
AppRegistry.registerComponent('App', () => {
  return App;
});

initialize
  .then(() => {
    AppRegistry.runApplication('App', {
      rootTag: document.getElementById('root'),
    });
  })
  .catch(
    // tslint:disable-next-line:no-console
    error => console.error(error)
  );
