"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PharmacyManagementPageContent = {
  emptyTableText: function emptyTableText(groupName) {
    return "No pharmacies found for group '" + groupName + "'.";
  },
  pharmacyEditIconText: function pharmacyEditIconText(name) {
    return "Change password for pharmacy '" + name + "'.";
  },
  tableCaption: function tableCaption(groupName) {
    return groupName + " Pharmacies";
  },
  tableColumns: {
    actions: {
      text: '',
      width: '2em'
    },
    addr1: {
      text: 'Address 1',
      width: ''
    },
    addr2: {
      text: 'Address 2',
      width: ''
    },
    city: {
      text: 'City',
      width: ''
    },
    name: {
      text: 'Name',
      width: ''
    },
    ncpdp: {
      text: 'NCPDP',
      width: '6em'
    },
    state: {
      text: 'State',
      width: '4em'
    }
  },
  tableFooter: function tableFooter(totalPharmacies) {
    return "Pharmacies: " + totalPharmacies;
  },
  title: 'Pharmacy Management'
};