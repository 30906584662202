"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var pricing_experience_navigator_screen_names_1 = require("../../pricing-experience-navigator/pricing-experience-navigator.screen-names");

exports.allowNavigationBack = function (currentScreen) {
  switch (currentScreen) {
    case pricing_experience_navigator_screen_names_1.NavigationScreenNames.priceLookupPage:
    case pricing_experience_navigator_screen_names_1.NavigationScreenNames.pharmacyPricingScreen:
      return true;
  }

  return false;
};