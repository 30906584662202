"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var base_text_style_1 = require("../../theming/pricing-system/base-text.style");

var theme_1 = require("../../theming/theme");

var tabsViewStyle = {
  flexDirection: 'row',
  margin: '.4em'
};

var tabItemBaseTextStyle = __assign(__assign({}, base_text_style_1.baseTextStyle), {
  borderRadius: 4,
  borderStyle: 'solid',
  borderWidth: 1,
  fontSize: '1.1em',
  marginRight: '.6em',
  padding: '.4em'
});

var tabItemTextStyle = __assign(__assign({}, tabItemBaseTextStyle), {
  borderColor: theme_1.BlueScale.darkest,
  color: theme_1.BlueScale.darkest
});

var tabItemSelectedTextStyle = __assign(__assign({}, tabItemBaseTextStyle), {
  borderColor: theme_1.GreyScale.lightest,
  color: theme_1.PurpleScale.darkest
});

exports.tabsStyle = {
  tabItemSelectedTextStyle: tabItemSelectedTextStyle,
  tabItemTextStyle: tabItemTextStyle,
  tabsViewStyle: tabsViewStyle
};