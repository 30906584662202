"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var theme_1 = require("../theme");

var base_text_style_1 = require("./base-text.style");

var autocompleteFilterHighlightTextStyle = __assign(__assign({}, base_text_style_1.baseTextStyle), {
  color: theme_1.PurpleScale.darkest,
  fontWeight: 'bold'
});

var autocompleteListViewStyle = {
  backgroundColor: 'white',
  borderColor: theme_1.GreyScale.light,
  borderRadius: 2,
  borderStyle: 'solid',
  borderWidth: 2,
  maxHeight: '20rem',
  minHeight: '20rem'
};
var autocompleteMoreItemsTextStyle = {
  color: theme_1.GreyScale.lighterDark,
  fontSize: 16,
  fontStyle: 'italic',
  marginTop: '.2em',
  textAlign: 'center'
};
var autocompleteInputContainerTextStyle = {
  alignSelf: 'stretch'
};
var autocompleteNoItemsTextStyle = {
  fontSize: 18,
  fontStyle: 'italic',
  textAlign: 'center'
};
var autocompleteSpinnerTextStyle = {
  backgroundColor: theme_1.GreyScale.lightest,
  paddingLeft: '.4em',
  position: 'absolute',
  right: '.5em',
  top: '.5em'
};
var autocompleteSpinnerViewStyle = {
  height: '100%',
  width: '100%'
};
exports.autocompleteSpinnerColor = theme_1.PurpleScale.darkest;
exports.autocompleteSpinnerSize = 16;
exports.autocompleteStyle = {
  autocompleteFilterHighlightTextStyle: autocompleteFilterHighlightTextStyle,
  autocompleteInputContainerTextStyle: autocompleteInputContainerTextStyle,
  autocompleteListViewStyle: autocompleteListViewStyle,
  autocompleteMoreItemsTextStyle: autocompleteMoreItemsTextStyle,
  autocompleteNoItemsTextStyle: autocompleteNoItemsTextStyle,
  autocompleteSpinnerTextStyle: autocompleteSpinnerTextStyle,
  autocompleteSpinnerViewStyle: autocompleteSpinnerViewStyle
};