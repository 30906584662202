"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var StatusActionKeys;

(function (StatusActionKeys) {
  StatusActionKeys["STATUS_ERROR_MESSAGE_HIDE"] = "STATUS_ERROR_MESSAGE_HIDE";
  StatusActionKeys["STATUS_ERROR_MESSAGE_SHOW"] = "STATUS_ERROR_MESSAGE_SHOW";
  StatusActionKeys["STATUS_INFO_MESSAGE_HIDE"] = "STATUS_INFO_MESSAGE_HIDE";
  StatusActionKeys["STATUS_INFO_MESSAGE_SHOW"] = "STATUS_INFO_MESSAGE_SHOW";
})(StatusActionKeys = exports.StatusActionKeys || (exports.StatusActionKeys = {}));