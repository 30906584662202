"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var price_lookup_action_keys_1 = require("./price-lookup-action-keys");

exports.priceLookupResponseAction = function (result) {
  return {
    payload: result,
    type: price_lookup_action_keys_1.PriceLookupActionKeys.PRICE_LOOKUP_RESPONSE
  };
};