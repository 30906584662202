"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var price_lookup_action_keys_1 = require("./price-lookup-action-keys");

exports.drugGetResponseAction = function (drug) {
  return {
    payload: drug,
    type: price_lookup_action_keys_1.PriceLookupActionKeys.DRUG_GET_RESPONSE
  };
};