"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var event_properties_factory_1 = require("./event-properties-factory");

var LoggerEventActionType;

(function (LoggerEventActionType) {
  LoggerEventActionType["authentication"] = "Authentication";
  LoggerEventActionType["logout"] = "Logout";
  LoggerEventActionType["priceLookup"] = "PriceLookup";
})(LoggerEventActionType = exports.LoggerEventActionType || (exports.LoggerEventActionType = {}));

var LoggerEventStatusType;

(function (LoggerEventStatusType) {
  LoggerEventStatusType["cancelled"] = "Cancelled";
  LoggerEventStatusType["failure"] = "Failure";
  LoggerEventStatusType["request"] = "Request";
  LoggerEventStatusType["success"] = "Success";
})(LoggerEventStatusType = exports.LoggerEventStatusType || (exports.LoggerEventStatusType = {}));

exports.Logger = {
  logEvent: function logEvent(actionType, payload) {
    return console.log(actionType, payload);
  },
  logException: function logException(error) {
    return console.error(error);
  },
  logStatusEvent: function logStatusEvent(action, status, authProvider) {
    var eventProps = event_properties_factory_1.EventPropertiesFactory.statusProperties(status, authProvider);
    exports.Logger.logEvent(action, eventProps);
  }
};