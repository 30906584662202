"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RequestHeaders = {
  accessTokenRequestHeader: 'x-access-token',
  deviceTokenRequestHeader: 'x-prescryptive-device-token',
  memberInfoRequestId: 'x-prescryptive-member-info-request-id',
  prescriptionInfoRequestId: 'x-prescryptive-prescripton-info-request-id',
  refreshAccountToken: 'x-prescryptive-refresh-account-token'
};