"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var theme_1 = require("../theme");

var base_text_style_1 = require("./base-text.style");

var heading_style_1 = require("./heading.style");

var errorPanelViewStyle = {
  alignSelf: 'center',
  backgroundColor: theme_1.GreyScale.lightest,
  borderColor: theme_1.GreyScale.darkest,
  borderRadius: 4,
  borderStyle: 'solid',
  borderWidth: 1,
  flexDirection: 'column',
  maxWidth: '36rem',
  paddingBottom: '1em',
  paddingLeft: '2em',
  paddingRight: '2em',
  paddingTop: '.5em',
  position: 'fixed',
  shadowColor: theme_1.GreyScale.light,
  shadowOffset: {
    width: 5,
    height: 5
  },
  shadowRadius: 5,
  top: '25%'
};

var errorPanelTextStyle = __assign(__assign({}, base_text_style_1.baseTextStyle), {
  color: theme_1.RedScale.regular
});

var errorPanelHeadingTextStyle = __assign(__assign({}, heading_style_1.headingStyle.heading2TextStyle), {
  color: theme_1.RedScale.regular,
  fontSize: '1.5rem',
  marginBottom: '1em',
  marginTop: '1.4em',
  textAlign: 'center'
});

var errorPanelHeadingWhenNoCloseTextStyle = __assign(__assign({}, errorPanelHeadingTextStyle), {
  marginTop: '.5em'
});

var errorPanelContextTextStyle = __assign(__assign({}, errorPanelTextStyle), {
  color: theme_1.GreyScale.darkest,
  flexGrow: 1,
  fontSize: '1em'
});

var errorPanelContextIdTextStyle = __assign(__assign({}, errorPanelContextTextStyle), {
  textAlign: 'left'
});

var errorPanelTimestampTextStyle = __assign(__assign({}, errorPanelContextTextStyle), {
  textAlign: 'right'
});

var errorPanelContextViewStyle = {
  alignItems: 'flex-end',
  flexDirection: 'row',
  marginBottom: '-.5em',
  marginLeft: '-1.5em',
  marginRight: '-1.5em',
  marginTop: '2em'
};
var errorPanelCloseButtonViewStyle = {
  position: 'absolute',
  right: 0,
  top: 0
};
var errorPanelCloseButtonTextStyle = {
  color: theme_1.GreyScale.dark
};
exports.errorPanelCloseIconSize = 18;
exports.errorPanelStyle = {
  errorPanelCloseButtonTextStyle: errorPanelCloseButtonTextStyle,
  errorPanelCloseButtonViewStyle: errorPanelCloseButtonViewStyle,
  errorPanelContextIdTextStyle: errorPanelContextIdTextStyle,
  errorPanelContextViewStyle: errorPanelContextViewStyle,
  errorPanelHeadingTextStyle: errorPanelHeadingTextStyle,
  errorPanelHeadingWhenNoCloseTextStyle: errorPanelHeadingWhenNoCloseTextStyle,
  errorPanelTextStyle: errorPanelTextStyle,
  errorPanelTimestampTextStyle: errorPanelTimestampTextStyle,
  errorPanelViewStyle: errorPanelViewStyle
};