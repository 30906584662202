"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var pricing_experience_features_1 = require("../../pricing-experience-features");

var FeaturesStateActionKeys;

(function (FeaturesStateActionKeys) {})(FeaturesStateActionKeys = exports.FeaturesStateActionKeys || (exports.FeaturesStateActionKeys = {}));

exports.featuresStateReducer = function (state) {
  if (state === void 0) {
    state = pricing_experience_features_1.PricingExperienceFeatures;
  }

  return state;
};