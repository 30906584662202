"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var symbols_1 = require("../../utils/symbols");

exports.LoginPageContent = {
  correctAccountInstructions: 'If this was the intended account, please sign out and try again.',
  incompleteLoginErrorHeading: 'Signin Incomplete',
  incompleteLoginMessage: function incompleteLoginMessage(accountName) {
    return "Although '" + accountName + " is a valid account, an error occurred signing in.";
  },
  incorrectAccountInstructions: 'If this was not the intended account, please sign out and try again with a different account.',
  instructions: 'Please sign in to proceed.',
  signinButtonText: 'Sign In',
  signoutButtonText: 'Sign Out',
  supportedBrowsersList: 'Chrome, Safari, Edge 79.0 on Desktop; Safari on iOS; Chrome on Android',
  supportedBrowsersTitle: 'Supported Browsers:',
  title: "Welcome to PricingRx" + symbols_1.Symbols.tradeMark
};