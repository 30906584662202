"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[typeof Symbol === "function" ? Symbol.iterator : "@@iterator"] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_native_router_flux_1 = require("react-native-router-flux");

var error_context_1 = require("../../../../../theming/pricing-system/error-context");

var authentication_1 = require("../../../authentication/authentication");

var logger_1 = require("../../../logging/logger");

var pricing_experience_navigator_screen_names_1 = require("../../../pricing-experience-navigator/pricing-experience-navigator.screen-names");

var busy_actions_1 = require("../../common/busy/busy.actions");

var error_message_show_action_1 = require("../../common/status/actions/error-message-show.action");

var logout_response_action_1 = require("./logout-response.action");

exports.logoutAsyncAction = function (thisArg) {
  return busy_actions_1.showBusyWhile(function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var authProvider, ex_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            authProvider = authentication_1.Authentication.provider;
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 5]);

            logger_1.Logger.logStatusEvent(logger_1.LoggerEventActionType.logout, logger_1.LoggerEventStatusType.request, authProvider);

            if (authProvider) {
              authProvider.logout();
            }

            return [4, dispatch(logout_response_action_1.logoutResponseAction())];

          case 2:
            _a.sent();

            react_native_router_flux_1.Actions[pricing_experience_navigator_screen_names_1.NavigationScreenNames.loginPage].call(thisArg);
            logger_1.Logger.logStatusEvent(logger_1.LoggerEventActionType.logout, logger_1.LoggerEventStatusType.success, authProvider);
            return [3, 5];

          case 3:
            ex_1 = _a.sent();
            return [4, dispatch(error_message_show_action_1.errorMessageShowAction(error_context_1.ErrorContext.logout))];

          case 4:
            _a.sent();

            logger_1.Logger.logException(ex_1);
            logger_1.Logger.logStatusEvent(logger_1.LoggerEventActionType.logout, logger_1.LoggerEventStatusType.failure, authProvider);
            return [3, 5];

          case 5:
            return [2];
        }
      });
    });
  });
};