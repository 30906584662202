"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[typeof Symbol === "function" ? Symbol.iterator : "@@iterator"] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var api_request_headers_1 = require("../experiences/guest-experience/api/api-request-headers");

var retry_policy_helper_1 = require("./retry-policies/retry-policy.helper");

var error_api_response_1 = require("../errors/error-api-response");

var constants_1 = require("../theming/constants");

function buildUrl(config, path, args) {
  var pathValue = config.paths[path];
  Object.keys(args).forEach(function (arg) {
    pathValue = pathValue.replace(arg, args[arg]);
  });
  var url = config.env.protocol + "://" + config.env.host + ":" + config.env.port + config.env.version + pathValue;
  return url;
}

exports.buildUrl = buildUrl;

exports.buildCommonHeaders = function (accountToken, deviceToken) {
  var headers = {};

  if (accountToken) {
    headers.Authorization = accountToken;
  }

  if (deviceToken) {
    headers[api_request_headers_1.RequestHeaders.deviceTokenRequestHeader] = deviceToken;
  }

  return headers;
};

function buildAuthHeaders(authToken) {
  return {
    Authorization: "Bearer " + authToken
  };
}

exports.buildAuthHeaders = buildAuthHeaders;

function call(endpoint, data, method, header, retryPolicy) {
  if (data === void 0) {
    data = null;
  }

  if (method === void 0) {
    method = 'GET';
  }

  if (header === void 0) {
    header = {};
  }

  return __awaiter(this, void 0, void 0, function () {
    var url, headerContent, requestOptions, result;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          url = "" + endpoint;
          headerContent = __assign({
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }, header);
          requestOptions = {
            body: (method === 'POST' || method === 'PUT') && data && JSON.stringify(data) || null,
            headers: new Headers(headerContent),
            method: method
          };
          return [4, exports.fetchRetry(url, requestOptions, fetch, retryPolicy)];

        case 1:
          result = _a.sent();
          return [2, result];
      }
    });
  });
}

exports.call = call;

exports.pause = function (duration) {
  return new Promise(function (res) {
    return setTimeout(res, duration);
  });
};

exports.fetchRetry = function (url, options, fetcher, retryPolicy) {
  return __awaiter(void 0, void 0, void 0, function () {
    var method, response, error_1;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          method = options.method;
          retryPolicy = retryPolicy || retry_policy_helper_1.getPolicy(method);
          _a.label = 1;

        case 1:
          _a.trys.push([1, 4,, 7]);

          return [4, fetcher(url, options)];

        case 2:
          response = _a.sent();

          if (response.ok) {
            return [2, response];
          }

          return [4, exports.pause(retryPolicy.pause)];

        case 3:
          _a.sent();

          retryPolicy = retryPolicy.getNextRetry(response, retryPolicy);
          return [3, 7];

        case 4:
          error_1 = _a.sent();

          if (retryPolicy.remaining === 1) {
            throw new error_api_response_1.ErrorApiResponse(constants_1.ErrorConstants.errorInternalServer);
          }

          return [4, exports.pause(retryPolicy.pause)];

        case 5:
          _a.sent();

          retryPolicy = retryPolicy.getNextRetry(error_1, retryPolicy);
          return [4, exports.fetchRetry(url, options, fetcher, retryPolicy)];

        case 6:
          return [2, _a.sent()];

        case 7:
          if (retryPolicy.remaining > 0) return [3, 1];
          _a.label = 8;

        case 8:
          return [2, response];
      }
    });
  });
};