// Copyright 2020 Prescryptive Health, inc
import {
  Authentication,
  IAuthentication,
} from 'prescryptive-ux-common/lib/experiences/pharmacy-pricing-experience/authentication/authentication';
import {
  PricingExperienceConfig,
  PricingExperienceConfigApiNames,
} from 'prescryptive-ux-common/lib/experiences/pharmacy-pricing-experience/pricing-experience-config';
import {
  buildUrl,
  IConfigState,
} from 'prescryptive-ux-common/lib/utils/api.helper';
import { AuthenticationProvider } from 'prescryptive-ux-common/lib/utils/authentication/authentication-provider';

export function initializeAuthentication(
  config: IConfigState<
    PricingExperienceConfigApiNames
  > = PricingExperienceConfig,
  authentication: IAuthentication = Authentication
) {
  const tenantId = config.auth.tenantId;
  const authUrl = buildUrl(config.apis.authenticationApi, 'login', {
    ':tenantId': tenantId,
  });

  const clientId = config.auth.clientId;
  const authProvider = new AuthenticationProvider(authUrl, clientId);

  authentication.provider = authProvider;
}
