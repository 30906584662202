"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var price_lookup_action_keys_1 = require("./price-lookup-action-keys");

exports.pharmacyGroupSelectAction = function (group) {
  return {
    payload: group,
    type: price_lookup_action_keys_1.PriceLookupActionKeys.PHARMACY_GROUP_SELECT
  };
};