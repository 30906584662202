"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var FormFormatter = function () {
  function FormFormatter() {}

  FormFormatter.formatWithLongName = function (formCode, allForms) {
    if (formCode === void 0) {
      formCode = '';
    }

    var drugForm = FormFormatter.getDrugForm(formCode, allForms);
    return drugForm ? drugForm.description : formCode;
  };

  FormFormatter.formatWithShortName = function (formCode, allForms) {
    if (formCode === void 0) {
      formCode = '';
    }

    var drugForm = FormFormatter.getDrugForm(formCode, allForms);
    return drugForm ? drugForm.abbreviation : formCode;
  };

  FormFormatter.getDrugForm = function (formCode, allForms) {
    if (allForms === void 0) {
      allForms = [];
    }

    var drugForms = allForms || [];
    return drugForms.find(function (form) {
      return form.formCode === formCode;
    });
  };

  return FormFormatter;
}();

exports.FormFormatter = FormFormatter;