"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var theme_1 = require("../theme");

var dialogOverlayZIndex = 99;
var dialogZIndex = dialogOverlayZIndex + 1;
var promptOverlayZIndex = dialogZIndex + 1;
var promptZIndex = promptOverlayZIndex + 1;
var dialogDefaultViewStyle = {
  backgroundColor: theme_1.GreyScale.lightest,
  borderColor: theme_1.GreyScale.light,
  borderRadius: 6,
  borderStyle: 'solid',
  borderWidth: 1,
  flexDirection: 'column',
  shadowColor: theme_1.GreyScale.light,
  shadowOffset: {
    height: 5,
    width: 5
  },
  shadowRadius: 10,
  zIndex: dialogZIndex
};
var dialogHeadingTextStyle = {
  borderBottomColor: theme_1.GreyScale.light,
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  fontSize: '1.3rem',
  marginBottom: 0,
  paddingBottom: '.2em',
  paddingTop: '.2em',
  textAlign: 'center'
};
var dialogContentViewStyle = {
  padding: '.4em'
};
var dialogButtonContainerViewStyle = {
  flexDirection: 'row',
  justifyContent: 'center',
  marginTop: '1em'
};
var dialogButtonDefaultViewStyle = {};
var dialogButtonDefaultTextStyle = {
  fontSize: '1rem'
};
var dialogButtonCloseViewStyle = {
  backgroundColor: 'inherit'
};

var dialogButtonCloseTextStyle = __assign(__assign({}, dialogButtonDefaultTextStyle), {
  color: theme_1.BlueScale.darkest,
  textDecorationLine: 'underline'
});

var dialogDefaultOverlayViewStyle = {
  backgroundColor: theme_1.GreyScale.lightest,
  bottom: 0,
  left: 0,
  opacity: 0.3,
  position: 'fixed',
  right: 0,
  top: 0,
  zIndex: dialogOverlayZIndex
};

var dialogPromptViewStyle = __assign(__assign({}, dialogDefaultViewStyle), {
  zIndex: promptZIndex
});

var dialogPromptOverlayViewStyle = __assign(__assign({}, dialogDefaultOverlayViewStyle), {
  zIndex: promptOverlayZIndex
});

exports.dialogStyle = {
  dialogButtonCloseTextStyle: dialogButtonCloseTextStyle,
  dialogButtonCloseViewStyle: dialogButtonCloseViewStyle,
  dialogButtonContainerViewStyle: dialogButtonContainerViewStyle,
  dialogButtonDefaultTextStyle: dialogButtonDefaultTextStyle,
  dialogButtonDefaultViewStyle: dialogButtonDefaultViewStyle,
  dialogContentViewStyle: dialogContentViewStyle,
  dialogDefaultOverlayViewStyle: dialogDefaultOverlayViewStyle,
  dialogDefaultViewStyle: dialogDefaultViewStyle,
  dialogHeadingTextStyle: dialogHeadingTextStyle,
  dialogPromptOverlayViewStyle: dialogPromptOverlayViewStyle,
  dialogPromptViewStyle: dialogPromptViewStyle
};