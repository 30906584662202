// Copyright 2018 Prescryptive Health, inc

import {
  IPricingExperienceConfig,
  PricingExperienceConfig,
} from 'prescryptive-ux-common/lib/experiences/pharmacy-pricing-experience/pricing-experience-config';
import { getMetaEnvironmentVariable, loadConfig } from './config.helper';

const authenticationApiConfigMap = new Map<string, string>([
  ['host', 'AUTHENTICATION_API_HOST'],
  ['port', 'AUTHENTICATION_API_PORT'],
]);

const authenticationIdConfigMap = new Map<string, string>([
  ['tenantId', 'AUTHENTICATION_TENANT_ID'],
  ['clientId', 'AUTHENTICATION_CLIENT_ID'],
]);

const domainDataSearchApiConfigMap = new Map<string, string>([
  ['host', 'DOMAINDATASEARCH_API_HOST'],
  ['port', 'DOMAINDATASEARCH_API_PORT'],
]);

const pricingExperienceApiConfigMap = new Map<string, string>([
  ['host', 'PRICINGEXPERIENCE_API_HOST'],
  ['port', 'PRICINGEXPERIENCE_API_PORT'],
]);

const telemetryConfigMap = new Map<string, string>([
  ['instrumentationKey', 'APPINSIGHTS_INSTRUMENTATION_KEY'],
  ['serviceName', 'APPINSIGHTS_SERVICE_NAME'],
]);

const transactionDetailsApiConfigMap = new Map<string, string>([
  ['host', 'TRANSACTIONDETAILS_API_HOST'],
  ['port', 'TRANSACTIONDETAILS_API_PORT'],
]);

const userManagementApiConfigMap = new Map<string, string>([
  ['host', 'USERMANAGEMENT_API_HOST'],
  ['port', 'USERMANAGEMENT_API_PORT'],
]);

export function initializeConfig(
  config: IPricingExperienceConfig = PricingExperienceConfig
) {
  loadConfig(config.apis.authenticationApi.env, authenticationApiConfigMap);
  loadConfig(config.auth, authenticationIdConfigMap);
  loadConfig(config.apis.domainDataSearchApi.env, domainDataSearchApiConfigMap);
  loadConfig(
    config.apis.transactionDetailsApi.env,
    transactionDetailsApiConfigMap
  );
  loadConfig(
    config.apis.pricingExperienceApi.env,
    pricingExperienceApiConfigMap
  );
  loadConfig(config.apis.userManagementApi.env, userManagementApiConfigMap);

  config.telemetry.serviceName = 'pharmacy-pricing-web';
  loadConfig(config.telemetry, telemetryConfigMap);

  config.subscriptionId = getMetaEnvironmentVariable(
    'SUBSCRIPTION_ID',
    config.subscriptionId,
    document
  );

  config.location = document.location;
}
