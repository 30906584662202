"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var status_action_keys_1 = require("./actions/status-action-keys");

exports.statusReducer = function (state, action) {
  if (state === void 0) {
    state = {};
  }

  switch (action.type) {
    case status_action_keys_1.StatusActionKeys.STATUS_ERROR_MESSAGE_SHOW:
      return errorMessageShowActionReducer(state, action);

    case status_action_keys_1.StatusActionKeys.STATUS_ERROR_MESSAGE_HIDE:
      return errorMessageHideActionReducer(state);

    case status_action_keys_1.StatusActionKeys.STATUS_INFO_MESSAGE_SHOW:
      return infoMessageShowActionReducer(state, action);

    case status_action_keys_1.StatusActionKeys.STATUS_INFO_MESSAGE_HIDE:
      return infoMessageHideActionReducer(state);
  }

  return state;
};

var errorMessageShowActionReducer = function errorMessageShowActionReducer(state, action) {
  var updatedState = __assign(__assign({}, state), {
    error: action.payload
  });

  return updatedState;
};

var errorMessageHideActionReducer = function errorMessageHideActionReducer(state) {
  var updatedState = __assign(__assign({}, state), {
    error: undefined
  });

  return updatedState;
};

var infoMessageShowActionReducer = function infoMessageShowActionReducer(state, action) {
  var updatedState = __assign(__assign({}, state), {
    infoMessage: action.payload
  });

  return updatedState;
};

var infoMessageHideActionReducer = function infoMessageHideActionReducer(state) {
  var updatedState = __assign(__assign({}, state), {
    infoMessage: undefined
  });

  return updatedState;
};