"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var price_lookup_action_keys_1 = require("./price-lookup-action-keys");

exports.waitCompleteAction = function (key) {
  return {
    payload: key,
    type: price_lookup_action_keys_1.PriceLookupActionKeys.WAIT_COMPLETE
  };
};