"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_navigation_redux_helpers_1 = require("react-navigation-redux-helpers");

var pricing_experience_navigator_1 = require("../../pricing-experience-navigator/pricing-experience-navigator");

var pricing_experience_navigator_screen_names_1 = require("../../pricing-experience-navigator/pricing-experience-navigator.screen-names");

exports.navigationReducer = react_navigation_redux_helpers_1.createNavigationReducer(pricing_experience_navigator_1.PricingExperienceNavigator);
exports.initialNavigationState = pricing_experience_navigator_1.PricingExperienceNavigator.router.getStateForAction(pricing_experience_navigator_1.PricingExperienceNavigator.router.getActionForPathAndParams(pricing_experience_navigator_screen_names_1.NavigationScreenNames.loginPage));