"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_redux_1 = require("react-redux");

var pharmacy_pricing_reducer_actions_1 = require("../../../../experiences/pharmacy-pricing-experience/store/pharmacy-pricing/pharmacy-pricing-reducer.actions");

var rule_details_checkbox_group_1 = require("./rule-details-checkbox-group");

var changeBrandCodeCheckboxValue = pharmacy_pricing_reducer_actions_1.pricingRuleDetailsSetArrayPropertyActionBuilder('brandCode');
var checkboxGroupActionProps = {
  onCheckboxClick: changeBrandCodeCheckboxValue
};

exports.mapStateToProps = function (state) {
  return __assign(__assign({}, state), {
    checkboxPropList: ['B', 'G', 'T'],
    id: 'brandCode',
    label: 'Brand Code: ',
    selectedValues: state.pharmacyPricing.selectedPricingRule.brandCode
  });
};

var actions = checkboxGroupActionProps;
exports.ConnectedBrandCodeCheckboxGroup = react_redux_1.connect(exports.mapStateToProps, actions)(rule_details_checkbox_group_1.RuleDetailsCheckboxGroup);