"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_native_router_flux_1 = require("react-native-router-flux");

var react_redux_1 = require("react-redux");

exports.ConnectedPricingExperienceRouter = react_redux_1.connect(function (state) {
  return {
    state: state.nav
  };
})(react_native_router_flux_1.Router);