// tslint:disable:file-name-casing - Copyright 2018 Prescryptive Health Inc

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { withErrorBoundary } from 'prescryptive-ux-common/lib/components/pricing/layout/error-boundary/with-error-boundary';
import { PricingExperience } from 'prescryptive-ux-common/lib/experiences/pharmacy-pricing-experience/pricing-experience';
import './index.css';
import { reactPlugIn } from './initialize-telemetry';

export default withAITracking(
  reactPlugIn,
  withErrorBoundary(PricingExperience),
  undefined,
  'ai-tracking-wrapper'
);
