"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var highlightStartTag = '<m>';
var highlightEndTag = '</m>';

var FormatPatternHelper = function () {
  function FormatPatternHelper() {}

  FormatPatternHelper.parse = function (pattern) {
    var segmentDelim = '^';
    var highlightStartRegex = new RegExp(highlightStartTag, 'g');
    var highlightEndRegex = new RegExp(highlightEndTag, 'g');
    var simplifiedPattern = FormatPatternHelper.mergeAdjacentTags(pattern);
    var segmentedPattern = simplifiedPattern.replace(highlightStartRegex, segmentDelim + highlightStartTag).replace(highlightEndRegex, highlightEndTag + segmentDelim);
    var patternParts = segmentedPattern.split(segmentDelim);
    var items = [];
    patternParts.forEach(function (patternPart) {
      if (!patternPart) {
        return;
      }

      var isHighlighted = patternPart.startsWith(highlightStartTag);
      items.push({
        isHighlighted: isHighlighted,
        text: patternPart.replace(highlightStartTag, '').replace(highlightEndTag, '')
      });
    });
    return items;
  };

  FormatPatternHelper.stripTags = function (pattern) {
    var highlightStartRegex = new RegExp(highlightStartTag, 'g');
    var highlightEndRegex = new RegExp(highlightEndTag, 'g');
    return pattern.replace(highlightStartRegex, '').replace(highlightEndRegex, '');
  };

  FormatPatternHelper.mergeAdjacentTags = function (pattern) {
    var regex = new RegExp(highlightEndTag + highlightStartTag, 'g');
    return pattern.replace(regex, '');
  };

  return FormatPatternHelper;
}();

exports.FormatPatternHelper = FormatPatternHelper;