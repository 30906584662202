"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var theme_1 = require("../theme");

var base_text_style_1 = require("./base-text.style");

var headingTextStyleBase = __assign(__assign({}, base_text_style_1.baseTextStyle), {
  color: theme_1.PurpleScale.darkest,
  marginBottom: '.5em',
  width: '100%'
});

var heading1TextStyle = __assign(__assign({}, headingTextStyleBase), {
  fontSize: '2.1rem',
  textAlign: 'center'
});

var heading2TextStyle = __assign(__assign({}, headingTextStyleBase), {
  fontSize: '1.7rem'
});

var heading3TextStyle = __assign(__assign({}, headingTextStyleBase), {
  fontSize: '1.5rem'
});

var heading4TextStyle = __assign(__assign({}, headingTextStyleBase), {
  fontSize: '1.3rem'
});

var heading5TextStyle = __assign(__assign({}, headingTextStyleBase), {
  fontSize: '1.2rem'
});

exports.headingStyle = {
  heading1TextStyle: heading1TextStyle,
  heading2TextStyle: heading2TextStyle,
  heading3TextStyle: heading3TextStyle,
  heading4TextStyle: heading4TextStyle,
  heading5TextStyle: heading5TextStyle
};