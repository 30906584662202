"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultRetryPolicyTemplate = {
  getNextRetry: function getNextRetry(_response, policy) {
    return __assign({}, policy);
  },
  pause: 0,
  remaining: 0
};
exports.DefaultPolicyTemplates = {
  DELETE: exports.DefaultRetryPolicyTemplate,
  GET: exports.DefaultRetryPolicyTemplate,
  POST: exports.DefaultRetryPolicyTemplate,
  PUT: exports.DefaultRetryPolicyTemplate
};

exports.getPolicy = function (key) {
  return exports.DefaultPolicyTemplates[key];
};