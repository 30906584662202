"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var pharmacy_mock_1 = require("../../../../components/pricing/models/pharmacy.mock");

var pharmacy_pricing_reducer_actions_1 = require("./pharmacy-pricing-reducer.actions");

exports.DefaultPharmacyPricingState = {
  pharmacies: pharmacy_mock_1.mockPharmacyGroupList,
  selectedPharmacy: pharmacy_mock_1.mockPharmacyGroupList[0],
  selectedPricingRule: pharmacy_mock_1.mockPharmacyGroupList[0] && pharmacy_mock_1.mockPharmacyGroupList[0].pricingRules[0]
};

var pricingPharmacyGroupSelectActionReducer = function pricingPharmacyGroupSelectActionReducer(state, action) {
  if (state === void 0) {
    state = exports.DefaultPharmacyPricingState;
  }

  return __assign(__assign({}, state), {
    selectedPharmacy: state.pharmacies.find(function (pharmacy) {
      return pharmacy.address === action.payload;
    }),
    selectedPricingRule: state.pharmacies.find(function (pharmacy) {
      return pharmacy.address === action.payload;
    }) && state.pharmacies.find(function (pharmacy) {
      return pharmacy.address === action.payload;
    }).pricingRules[0]
  });
};

var pricingRuleSelectActionReducer = function pricingRuleSelectActionReducer(state, action) {
  if (state === void 0) {
    state = exports.DefaultPharmacyPricingState;
  }

  if (!state.selectedPharmacy) {
    throw new Error("Tried to select pricing rule before pharmacy group is selected");
  }

  var newSelectedRule = state.selectedPharmacy.pricingRules.find(function (rule) {
    return rule.address === action.payload;
  });
  return __assign(__assign({}, state), {
    selectedPricingRule: newSelectedRule
  });
};

var pricingRuleDetailsSetPropertyActionReducer = function pricingRuleDetailsSetPropertyActionReducer(state, action) {
  var _a;

  if (state === void 0) {
    state = exports.DefaultPharmacyPricingState;
  }

  if (!state.selectedPharmacy) {
    throw new Error("Tried to set rule property before pharmacy group is selected");
  }

  if (!state.selectedPricingRule) {
    throw new Error("Tried to set rule property before pricing rule is selected");
  }

  var emptyFieldEval = action.payload.propertyValue !== "" ? action.payload.propertyValue : NaN;
  var value = !isNaN(Number(emptyFieldEval)) ? Number(emptyFieldEval) : emptyFieldEval;
  return __assign(__assign({}, state), {
    selectedPricingRule: __assign(__assign({}, state.selectedPricingRule), (_a = {}, _a[action.payload.propertyKey] = value, _a))
  });
};

var pricingRuleDetailsSetArrayPropertyActionReducer = function pricingRuleDetailsSetArrayPropertyActionReducer(state, action) {
  var _a;

  if (state === void 0) {
    state = exports.DefaultPharmacyPricingState;
  }

  if (!state.selectedPharmacy) {
    throw new Error("Tried to set rule property before pharmacy group is selected");
  }

  if (!state.selectedPricingRule) {
    throw new Error("Tried to set rule property before pricing rule is selected");
  }

  return __assign(__assign({}, state), {
    selectedPricingRule: __assign(__assign({}, state.selectedPricingRule), (_a = {}, _a[action.payload.propertyKey] = action.payload.propertyValue, _a))
  });
};

var pricingRuleDetailsSetSelectorPropertyActionReducer = function pricingRuleDetailsSetSelectorPropertyActionReducer(state, action) {
  var _a;

  if (state === void 0) {
    state = exports.DefaultPharmacyPricingState;
  }

  if (!state.selectedPharmacy) {
    throw new Error("Tried to set rule property before pharmacy group is selected");
  }

  if (!state.selectedPricingRule) {
    throw new Error("Tried to set rule property before pricing rule is selected");
  }

  return __assign(__assign({}, state), {
    selectedPricingRule: __assign(__assign({}, state.selectedPricingRule), (_a = {}, _a[action.payload.propertyKey] = action.payload.propertyValue, _a))
  });
};

var pricingRuleDetailsSaveActionReducer = function pricingRuleDetailsSaveActionReducer(state) {
  if (state === void 0) {
    state = exports.DefaultPharmacyPricingState;
  }

  if (!state.selectedPricingRule) {
    throw new Error("Tried to save without selecting a prescription");
  }

  if (!state.selectedPharmacy) {
    throw new Error("Tried to save without selecting a pharmacy");
  }

  var pharmacyAddress = state.selectedPharmacy.address;
  var ruleAddress = state.selectedPricingRule.address;
  var pharmacyIndex = state.pharmacies.findIndex(function (p) {
    return p.address === pharmacyAddress;
  });
  var pharmacy = state.pharmacies[pharmacyIndex];
  var ruleIndex = pharmacy.pricingRules.findIndex(function (r) {
    return r.address === ruleAddress;
  });
  var updatedPharmacies = state.pharmacies.map(function (p) {
    if (p.address === pharmacyAddress) {
      p.pricingRules[ruleIndex] = state.selectedPricingRule;
    }

    return p;
  });
  return __assign(__assign({}, state), {
    pharmacies: updatedPharmacies
  });
};

var pricingRuleDetailsSetDatePropertyActionReducer = function pricingRuleDetailsSetDatePropertyActionReducer(state, action) {
  var _a, _b, _c, _d;

  if (state === void 0) {
    state = exports.DefaultPharmacyPricingState;
  }

  if (!state.selectedPharmacy) {
    throw new Error("Tried to set rule property before pharmacy group is selected");
  }

  if (!state.selectedPricingRule) {
    throw new Error("Tried to set rule property before pricing rule is selected");
  }

  var parsedDateStringArray = action.payload.propertyValue.toString().split("/");
  var yearInputLength = parseInt(parsedDateStringArray[2], undefined).toString().length;
  var dateValue = new Date(parseInt(parsedDateStringArray[2], undefined), parseInt(parsedDateStringArray[0], undefined) - 1, parseInt(parsedDateStringArray[1], undefined));

  if (!isNaN(dateValue.getTime()) && yearInputLength === 4) {
    return __assign(__assign({}, state), {
      selectedPricingRule: __assign(__assign({}, state.selectedPricingRule), (_a = {}, _a[action.payload.propertyKey] = dateValue, _a.errors = (_b = {}, _b[action.payload.propertyKey] = false, _b), _a))
    });
  } else {
    return __assign(__assign({}, state), {
      selectedPricingRule: __assign(__assign({}, state.selectedPricingRule), (_c = {}, _c[action.payload.propertyKey] = action.payload.propertyValue, _c.errors = (_d = {}, _d[action.payload.propertyKey] = true, _d), _c))
    });
  }
};

exports.pharmacyPricingReducer = function (state, action) {
  if (state === void 0) {
    state = exports.DefaultPharmacyPricingState;
  }

  switch (action.type) {
    case pharmacy_pricing_reducer_actions_1.PharmacyPricingActionKeys.PRICING_PHARMACY_GROUP_SELECT:
      return pricingPharmacyGroupSelectActionReducer(state, action);

    case pharmacy_pricing_reducer_actions_1.PharmacyPricingActionKeys.PRICING_RULE_SELECT:
      return pricingRuleSelectActionReducer(state, action);

    case pharmacy_pricing_reducer_actions_1.PharmacyPricingActionKeys.PRICING_RULE_DETAILS_SET_PROPERTY:
      return pricingRuleDetailsSetPropertyActionReducer(state, action);

    case pharmacy_pricing_reducer_actions_1.PharmacyPricingActionKeys.PRICING_RULE_DETAILS_SET_ARRAY_PROPERTY:
      return pricingRuleDetailsSetArrayPropertyActionReducer(state, action);

    case pharmacy_pricing_reducer_actions_1.PharmacyPricingActionKeys.PRICING_RULE_UPDATE_START:
      return pricingRuleDetailsSaveActionReducer(state);

    case pharmacy_pricing_reducer_actions_1.PharmacyPricingActionKeys.PRICING_RULE_DETAILS_SET_DATE_PROPERTY:
      return pricingRuleDetailsSetDatePropertyActionReducer(state, action);

    case pharmacy_pricing_reducer_actions_1.PharmacyPricingActionKeys.PRICING_RULE_DETAILS_SET_SELECTOR_PROPERTY:
      return pricingRuleDetailsSetSelectorPropertyActionReducer(state, action);
  }

  return state;
};