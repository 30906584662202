"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var store_1 = require("../../store/store");

var navigation_reducer_1 = require("./navigation/navigation-reducer");

var navigation_reducer_middleware_1 = require("./navigation/navigation-reducer.middleware");

var root_reducer_1 = require("./root-reducer");

exports.buildPricingExperienceStore = function () {
  return store_1.configureStore(root_reducer_1.rootReducer, {
    nav: navigation_reducer_1.initialNavigationState
  }, [navigation_reducer_middleware_1.navigationMiddleware]);
};