"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var StrengthFormatter = function () {
  function StrengthFormatter() {}

  StrengthFormatter.format = function (strength) {
    if (!strength) {
      return '';
    }

    var value = strength.value || '';
    return "" + value + StrengthFormatter.formatUnit(strength.unit);
  };

  StrengthFormatter.formatUnit = function (unit) {
    if (unit === void 0) {
      unit = '';
    }

    return unit.toLowerCase();
  };

  return StrengthFormatter;
}();

exports.StrengthFormatter = StrengthFormatter;