"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var PriceLookupActionKeys;

(function (PriceLookupActionKeys) {
  PriceLookupActionKeys["DRUG_GET_RESPONSE"] = "DRUG_GET_RESPONSE";
  PriceLookupActionKeys["DRUG_SEARCH_IN_PROGRESS"] = "DRUG_SEARCH_IN_PROGRESS";
  PriceLookupActionKeys["DRUG_SEARCH_RESPONSE"] = "DRUG_SEARCH_RESPONSE";
  PriceLookupActionKeys["GET_ALL_DRUG_FORMS_RESPONSE"] = "GET_ALL_DRUG_FORMS_RESPONSE";
  PriceLookupActionKeys["GET_ALL_PACKAGE_TYPES_RESPONSE"] = "GET_ALL_PACKAGE_TYPES_RESPONSE";
  PriceLookupActionKeys["GET_PHARMACIES_RESPONSE"] = "GET_PHARMACIES_RESPONSE";
  PriceLookupActionKeys["GET_PHARMACY_GROUPS_RESPONSE"] = "GET_PHARMACY_GROUPS_RESPONSE";
  PriceLookupActionKeys["LOGIN_CANCELLED_RESPONSE"] = "LOGIN_CANCELLED_RESPONSE";
  PriceLookupActionKeys["LOGIN_INCOMPLETE_ERROR_SHOW"] = "LOGIN_INCOMPLETE_ERROR_SHOW";
  PriceLookupActionKeys["LOGIN_RESPONSE"] = "LOGIN_RESPONSE";
  PriceLookupActionKeys["LOGOUT_RESPONSE"] = "LOGOUT_RESPONSE";
  PriceLookupActionKeys["PASSWORD_CLEAR"] = "PASSWORD_CLEAR";
  PriceLookupActionKeys["PASSWORD_GENERATE_RESPONSE"] = "PASSWORD_GENERATE_RESPONSE";
  PriceLookupActionKeys["PHARMACY_GROUP_SELECT"] = "PHARMACY_GROUP_SELECT";
  PriceLookupActionKeys["PRICE_LOOKUP_IN_PROGRESS"] = "PRICE_LOOKUP_IN_PROGRESS";
  PriceLookupActionKeys["PRICE_LOOKUP_RESPONSE"] = "PRICE_LOOKUP_RESPONSE";
  PriceLookupActionKeys["PRICE_RESET"] = "PRICE_RESET";
  PriceLookupActionKeys["WAIT_COMPLETE"] = "WAIT_COMPLETE";
})(PriceLookupActionKeys = exports.PriceLookupActionKeys || (exports.PriceLookupActionKeys = {}));

var PriceLookupWaitKeys;

(function (PriceLookupWaitKeys) {
  PriceLookupWaitKeys["TRANSACTION_DETAILS"] = "TRANSACTION_DETAILS";
})(PriceLookupWaitKeys = exports.PriceLookupWaitKeys || (exports.PriceLookupWaitKeys = {}));