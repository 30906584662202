"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var NavigationScreenNames;

(function (NavigationScreenNames) {
  NavigationScreenNames["loginPage"] = "loginPage";
  NavigationScreenNames["pharmacyManagementPage"] = "pharmancyManagementPage";
  NavigationScreenNames["pharmacyPricingScreen"] = "pharmacyPricingScreen";
  NavigationScreenNames["priceLookupPage"] = "priceLookupPage";
})(NavigationScreenNames = exports.NavigationScreenNames || (exports.NavigationScreenNames = {}));