"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_redux_1 = require("react-redux");

var drug_search_async_action_1 = require("../../store/price-lookup/actions/drug-search-async.action");

var drug_search_field_1 = require("./drug-search-field");

exports.mapStateToProps = function (state, ownProps) {
  var priceLookupState = state.priceLookup;
  var config = state.config;
  return __assign({
    drugForms: priceLookupState.drugForms,
    drugSearchDebounceTimeout: config.drugSearchDebounceMs,
    drugSearchMinChars: config.drugSearchMinChars,
    drugSearchResult: priceLookupState.drugSearchResult,
    initialNumToRender: config.drugSearchInitialNumToRender,
    isSearchInProgress: priceLookupState.drugSearchRequestCount > 0
  }, ownProps);
};

exports.actions = {
  drugSearch: drug_search_async_action_1.drugSearchAsyncAction
};
exports.DrugSearchFieldConnected = react_redux_1.connect(exports.mapStateToProps, exports.actions, null, {
  forwardRef: true
})(drug_search_field_1.DrugSearchField);