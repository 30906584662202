"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DrugSearchHelpDialogContent = {
  closeButtonText: 'Close',
  searchByNameLabel: 'Search by Name:',
  searchByNdcLabel: 'Search by NDC:',
  searchByPartialNameLabel: 'Search by Partial Name:',
  searchWithBrandLabel: 'Search w/ Brand',
  searchWithUnitsLabel: 'Search w/ Units:',
  searchByNameExample: 'lyrica',
  searchByNdcExample: '0071101441',
  searchByPartialNameExample: 'pregab 50',
  searchWithBrandExample: 'pregab brand 50',
  searchWithUnitsExample: 'lyrica50',
  title: 'Drug Search Help'
};