"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});

var errors_1 = require("../../../theming/pricing-system/errors");

var LoginInProgressError = function (_super) {
  __extends(LoginInProgressError, _super);

  function LoginInProgressError() {
    var _this = _super.call(this) || this;

    Object.setPrototypeOf(_this, LoginInProgressError.prototype);
    _this.message = errors_1.Errors.loginInProgress;
    return _this;
  }

  return LoginInProgressError;
}(Error);

exports.LoginInProgressError = LoginInProgressError;