"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var PharmacyPricingActionKeys;

(function (PharmacyPricingActionKeys) {
  PharmacyPricingActionKeys["PRICING_PHARMACY_GROUP_SELECT"] = "PRICING_PHARMACY_GROUP_SELECT";
  PharmacyPricingActionKeys["PRICING_RULE_SELECT"] = "PRICING_RULE_SELECT";
  PharmacyPricingActionKeys["PRICING_RULE_DETAILS_SET_PROPERTY"] = "PRICING_RULE_DETAILS_SET_PROPERTY";
  PharmacyPricingActionKeys["PRICING_RULE_DETAILS_SET_ARRAY_PROPERTY"] = "PRICING_RULE_DETAILS_SET_ARRAY_PROPERTY";
  PharmacyPricingActionKeys["PRICING_RULE_DETAILS_SET_DATE_PROPERTY"] = "PRICING_RULE_DETAILS_SET_DATE_PROPERTY";
  PharmacyPricingActionKeys["PRICING_RULE_DETAILS_SET_SELECTOR_PROPERTY"] = "PRICING_RULE_DETAILS_SET_SELECTOR_PROPERTY";
  PharmacyPricingActionKeys["PRICING_QUERY_CRITERIA_SET_PROPERTY"] = "PRICING_QUERY_CRITERIA_SET_PROPERTY";
  PharmacyPricingActionKeys["PRICING_RULE_CREATE_START"] = "PRICING_RULE_CREATE_START";
  PharmacyPricingActionKeys["PRICING_RULE_CREATE_END"] = "PRICING_RULE_CREATE_END";
  PharmacyPricingActionKeys["PRICING_RULE_CREATE_FAIL"] = "PRICING_RULE_CREATE_FAIL";
  PharmacyPricingActionKeys["PRICING_RULE_UPDATE_START"] = "PRICING_RULE_UPDATE_START";
  PharmacyPricingActionKeys["PRICING_RULE_UPDATE_END"] = "PRICING_RULE_UPDATE_END";
  PharmacyPricingActionKeys["PRICING_RULE_UPDATE_FAIL"] = "PRICING_RULE_UPDATE_FAIL";
  PharmacyPricingActionKeys["PRICING_RULE_DISCARD_START"] = "PRICING_RULE_DISCARD_START";
  PharmacyPricingActionKeys["PRICING_RULE_DISCARD_END"] = "PRICING_RULE_DISCARD_END";
  PharmacyPricingActionKeys["PRICING_RULE_DISCARD_FAIL"] = "PRICING_RULE_DISCARD_FAIL";
  PharmacyPricingActionKeys["PRICING_RULE_ACTIVATE_START"] = "PRICING_RULE_ACTIVATE_START";
  PharmacyPricingActionKeys["PRICING_RULE_ACTIVATE_END"] = "PRICING_RULE_ACTIVATE_END";
  PharmacyPricingActionKeys["PRICING_RULE_ACTIVATE_FAIL"] = "PRICING_RULE_ACTIVATE_FAIL";
  PharmacyPricingActionKeys["PRICING_RULE_DEACTIVATE_START"] = "PRICING_RULE_DEACTIVATE_START";
  PharmacyPricingActionKeys["PRICING_RULE_DEACTIVATE_END"] = "PRICING_RULE_DEACTIVATE_END";
  PharmacyPricingActionKeys["PRICING_RULE_DEACTIVATE_FAIL"] = "PRICING_RULE_DEACTIVATE_FAIL";
  PharmacyPricingActionKeys["PRICING_PHARMACY_GROUP_LIST_READ_START"] = "PRICING_PHARMACY_GROUP_LIST_READ_START";
  PharmacyPricingActionKeys["PRICING_PHARMACY_GROUP_LIST_READ_END"] = "PRICING_PHARMACY_GROUP_LIST_READ_END";
  PharmacyPricingActionKeys["PRICING_PHARMACY_GROUP_LIST_READ_FAIL"] = "PRICING_PHARMACY_GROUP_LIST_READ_FAIL";
  PharmacyPricingActionKeys["PRICING_PHARMACY_GROUP_DETAILS_READ_START"] = "PRICING_PHARMACY_GROUP_DETAILS_READ_START";
  PharmacyPricingActionKeys["PRICING_PHARMACY_GROUP_DETAILS_READ_END"] = "PRICING_PHARMACY_GROUP_DETAILS_READ_END";
  PharmacyPricingActionKeys["PRICING_PHARMACY_GROUP_DETAILS_READ_FAIL"] = "PRICING_PHARMACY_GROUP_DETAILS_READ_FAIL";
  PharmacyPricingActionKeys["PRICING_RULE_READ_START"] = "PRICING_RULE_READ_START";
  PharmacyPricingActionKeys["PRICING_RULE_READ_END"] = "PRICING_RULE_READ_END";
  PharmacyPricingActionKeys["PRICING_RULE_READ_FAIL"] = "PRICING_RULE_READ_FAIL";
  PharmacyPricingActionKeys["PRICING_QUERY_START"] = "PRICING_QUERY_START";
  PharmacyPricingActionKeys["PRICING_QUERY_END"] = "PRICING_QUERY_END";
  PharmacyPricingActionKeys["PRICING_QUERY_FAIL"] = "PRICING_QUERY_FAIL";
  PharmacyPricingActionKeys["PRICING_PHARMACY_SET_DATE_PROPERTY"] = "PRICING_PHARMACY_SET_DATE_PROPERTY";
})(PharmacyPricingActionKeys = exports.PharmacyPricingActionKeys || (exports.PharmacyPricingActionKeys = {}));

exports.pricingPharmacyGroupSelectAction = function (address) {
  return {
    payload: address,
    type: PharmacyPricingActionKeys.PRICING_PHARMACY_GROUP_SELECT
  };
};

exports.pricingRuleSelectAction = function (address) {
  return {
    payload: address,
    type: PharmacyPricingActionKeys.PRICING_RULE_SELECT
  };
};

exports.pricingRuleUpdateStartAction = function () {
  return {
    payload: undefined,
    type: PharmacyPricingActionKeys.PRICING_RULE_UPDATE_START
  };
};

exports.pricingRuleDetailsSetDatePropertyActionBuilder = function (propertyKey) {
  return function (propertyValue) {
    return {
      payload: {
        propertyKey: propertyKey,
        propertyValue: propertyValue
      },
      type: PharmacyPricingActionKeys.PRICING_RULE_DETAILS_SET_DATE_PROPERTY
    };
  };
};

exports.pricingRuleDetailsSetStringPropertyActionBuilder = function (propertyKey) {
  return function (propertyValue) {
    return {
      payload: {
        propertyKey: propertyKey,
        propertyValue: propertyValue
      },
      type: PharmacyPricingActionKeys.PRICING_RULE_DETAILS_SET_PROPERTY
    };
  };
};

exports.pricingRuleDetailsSetSelectorPropertyActionBuilder = function (propertyKey) {
  return function (propertyValue) {
    return {
      payload: {
        propertyKey: propertyKey,
        propertyValue: propertyValue
      },
      type: PharmacyPricingActionKeys.PRICING_RULE_DETAILS_SET_SELECTOR_PROPERTY
    };
  };
};

exports.pricingRuleDetailsSetArrayPropertyActionBuilder = function (propertyKey) {
  return function (propertyValue) {
    return {
      payload: {
        propertyKey: propertyKey,
        propertyValue: propertyValue
      },
      type: PharmacyPricingActionKeys.PRICING_RULE_DETAILS_SET_ARRAY_PROPERTY
    };
  };
};