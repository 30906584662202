"use strict";

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var assets_1 = require("../theming/assets");

exports.ResolvedImagesMap = new Map();

function requireFiles(requirer, files, path) {
  var params = [];

  for (var _i = 3; _i < arguments.length; _i++) {
    params[_i - 3] = arguments[_i];
  }

  var fileMap = new Map();
  files.forEach(function (fileName, fileKey) {
    var source = requireAsset.apply(void 0, __spreadArrays([requirer, fileName, path], params));

    if (source) {
      fileMap.set(fileKey, source);
    }
  });
  return fileMap;
}

exports.requireFiles = requireFiles;

function requireAsset(requirer, file, path) {
  var params = [];

  for (var _i = 3; _i < arguments.length; _i++) {
    params[_i - 3] = arguments[_i];
  }

  var asset = __spreadArrays(["" + path + file], params).join('');

  return requirer(asset);
}

exports.requireAsset = requireAsset;

function getResolvedImageSource(name) {
  var image = exports.ResolvedImagesMap.get(name);
  return image;
}

exports.getResolvedImageSource = getResolvedImageSource;

function initializeImageAssets(fileKeyMap) {
  fileKeyMap.forEach(function (source, key) {
    assets_1.ImageKeysMap.forEach(function (fileKey, imageName) {
      if (fileKey === key) {
        exports.ResolvedImagesMap.set(imageName, source);
      }
    });
  });
}

exports.initializeImageAssets = initializeImageAssets;