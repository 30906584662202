"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var theme_1 = require("../theme");

var base_text_style_1 = require("./base-text.style");

var tableViewStyle = {
  flexGrow: 1,
  flexShrink: 1
};
var tableBorderViewStyle = {
  borderColor: theme_1.GreyScale.regular,
  borderStyle: 'solid'
};
var tableCaptionTextStyle = {
  fontSize: '1.5rem',
  marginBottom: '.5em',
  textAlign: 'center'
};
var tableActionsViewStyle = {
  flexDirection: 'row',
  justifyContent: 'flex-start',
  marginBottom: '.1em'
};
var tableActionsTextStyle = {
  fontSize: '1rem'
};

var tableRowViewStyle = __assign(__assign({}, tableBorderViewStyle), {
  borderBottomWidth: 1,
  borderLeftWidth: 2,
  borderRightWidth: 1
});

var tableFirstRowViewStyle = {
  borderTopWidth: 2
};
var tableLastRowViewStyle = {
  borderBottomWidth: 2
};
var tableOddRowExtraViewStyle = {
  backgroundColor: theme_1.GreyScale.lighter
};

var tableHeaderRowViewStyle = __assign(__assign(__assign({}, tableRowViewStyle), tableFirstRowViewStyle), {
  backgroundColor: theme_1.GreyScale.light,
  borderBottomWidth: 0
});

var tableCellTextStyle = __assign(__assign(__assign({}, base_text_style_1.baseTextStyle), tableBorderViewStyle), {
  borderRightWidth: 1,
  flex: 1,
  paddingBottom: '.2em',
  paddingLeft: '.4em',
  paddingRight: '.4em',
  paddingTop: '.2em'
});

var tableHeaderCellTextStyle = __assign(__assign({}, tableCellTextStyle), {
  color: theme_1.PurpleScale.darkest,
  textAlign: 'center'
});

var tableBodyCellTextStyle = tableCellTextStyle;

var tableFooterTextStyle = __assign(__assign({}, base_text_style_1.baseTextStyle), {
  fontSize: '1rem',
  marginLeft: '.2em',
  marginTop: '.2em'
});

var tableEmptyTextStyle = __assign(__assign({}, base_text_style_1.baseTextStyle), {
  color: theme_1.GreyScale.lighterDark,
  fontStyle: 'italic',
  marginBottom: '1em',
  marginTop: '1em',
  textAlign: 'center'
});

var tableEmptyViewStyle = __assign(__assign({}, tableRowViewStyle), {
  borderBottomWidth: 2,
  borderLeftWidth: 2,
  borderRightWidth: 2,
  borderTopWidth: 2
});

exports.tableStyle = {
  tableActionsTextStyle: tableActionsTextStyle,
  tableActionsViewStyle: tableActionsViewStyle,
  tableBodyCellTextStyle: tableBodyCellTextStyle,
  tableBorderViewStyle: tableBorderViewStyle,
  tableCaptionTextStyle: tableCaptionTextStyle,
  tableCellTextStyle: tableCellTextStyle,
  tableEmptyTextStyle: tableEmptyTextStyle,
  tableEmptyViewStyle: tableEmptyViewStyle,
  tableFirstRowViewStyle: tableFirstRowViewStyle,
  tableFooterTextStyle: tableFooterTextStyle,
  tableHeaderCellTextStyle: tableHeaderCellTextStyle,
  tableHeaderRowViewStyle: tableHeaderRowViewStyle,
  tableLastRowViewStyle: tableLastRowViewStyle,
  tableOddRowExtraViewStyle: tableOddRowExtraViewStyle,
  tableRowViewStyle: tableRowViewStyle,
  tableViewStyle: tableViewStyle
};