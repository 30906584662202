"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImageKeysMap = new Map();
exports.ImageKeysMap.set('backButton', 'leftArrow');
exports.ImageKeysMap.set('bartellPharmacyIcon', 'bartellPharmacyIcon');
exports.ImageKeysMap.set('bartellPharmacyLogo', 'bartellPharmacyLogo');
exports.ImageKeysMap.set('cvsPharmacyLogo', 'cvsPharmacy1Logo');
exports.ImageKeysMap.set('fredMeyerPharmacyLogo', 'fredMeyerPharmacyLogo');
exports.ImageKeysMap.set('qfcPharmacyLogo', 'qfcPharmacyLogo');
exports.ImageKeysMap.set('walmartPharmacyLogo', 'walmartPharmacyLogo');
exports.ImageKeysMap.set('filterButton', 'downArrow');
exports.ImageKeysMap.set('prescryptiveLogo', 'prescryptiveLogo');
exports.ImageKeysMap.set('splashScreen', 'splashScreen');
exports.ImageKeysMap.set('dropDownArrowButton', 'dropDownArrowButton');
exports.ImageKeysMap.set('checkCircleLogo', 'checkCircleLogo');
exports.ImageKeysMap.set('unCheckCircleLogo', 'unCheckCircleLogo');
exports.ImageKeysMap.set('dropUpArrowButton', 'dropUpArrowButton');
exports.ImageKeysMap.set('closeRedButton', 'closeRedButton');
exports.ImageKeysMap.set('closeWhiteButton', 'closeWhiteButton');
exports.ImageKeysMap.set('checkBoxButton', 'checkBoxButton');
exports.ImageKeysMap.set('unCheckBoxButton', 'unCheckBoxButton');
exports.ImageKeysMap.set('closeGreyButton', 'closeGreyButton');
exports.ImageKeysMap.set('headerPrescryptiveTrademarkLogo', 'headerPrescryptiveTrademarkLogo');
exports.ImageKeysMap.set('drawerhamburger', 'drawerhamburger');
exports.ImageKeysMap.set('rightArrowButton', 'rightArrowButton');
exports.ImageKeysMap.set('iconHappyMeds', 'iconHappyMeds');
exports.ImageKeysMap.set('iconForm', 'iconForm');
exports.ImageKeysMap.set('iconBlankBottle', 'iconBlankBottle');
exports.ImageKeysMap.set('iconRx', 'iconRx');
exports.ImageKeysMap.set('forwardArrowButton', 'forwardArrowButton');
exports.ImageKeysMap.set('backwardArrowButton', 'backwardArrowButton');
exports.ImageKeysMap.set('sideMenuSupportIcon', 'sideMenuSupportIcon');
exports.ImageKeysMap.set('sideMenuMembersIcon', 'sideMenuMembersIcon');
exports.ImageKeysMap.set('sideMenuAlertsIcon', 'sideMenuAlertsIcon');
exports.ImageKeysMap.set('sideMenuBenefitIcon', 'sideMenuBenefitIcon');
exports.ImageKeysMap.set('sideMenuCloseMenuIcon', 'sideMenuCloseMenuIcon');
exports.ImageKeysMap.set('sideMenuAvatarIcon', 'sideMenuAvatarIcon');
exports.ImageKeysMap.set('supportDialButtonIcon', 'supportDialButtonIcon');
exports.ImageKeysMap.set('locationIcon', 'locationIcon');
exports.ImageKeysMap.set('piggyBankIcon', 'piggyBankIcon');
exports.ImageKeysMap.set('successTickmark', 'successTickmark');
exports.ImageKeysMap.set('feedbackThumbsUp', 'feedbackThumbsUp');
exports.ImageKeysMap.set('feedbackThumbsDown', 'feedbackThumbsDown');
exports.ImageKeysMap.set('selectedFeedbackThumbsUp', 'selectedFeedbackThumbsUp');
exports.ImageKeysMap.set('selectedFeedbackThumbsDown', 'selectedFeedbackThumbsDown');
exports.ImageKeysMap.set('bartellDrugsCoupon', 'bartellDrugsCoupon');
exports.ImageKeysMap.set('iconCheckCircleRegular', 'iconCheckCircleRegular');
exports.ImageKeysMap.set('alertCircle', 'alertCircle');
exports.ImageKeysMap.set('errorCircle', 'errorCircle');
exports.ImageKeysMap.set('yellowBang', 'yellowBang');
exports.ImageKeysMap.set('groupIcon', 'groupIcon');
exports.ImageKeysMap.set('digitalCardBottomBackground', 'digitalCardBottomBackground');
exports.ImageKeysMap.set('digitalCardTopBackground', 'digitalCardTopBackground');
exports.ImageKeysMap.set('healthIdCard', 'healthIdCard');