"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_redux_1 = require("react-redux");

var authentication_1 = require("../../authentication/authentication");

var login_async_action_1 = require("../../store/price-lookup/actions/login-async.action");

var logout_async_action_1 = require("../../store/price-lookup/actions/logout-async.action");

var login_page_1 = require("./login-page");

exports.mapStateToProps = function (state) {
  var _a = state.priceLookup.authorization,
      accountName = _a.accountName,
      isLoginIncomplete = _a.isLoginIncomplete;
  var isLoggedIn = authentication_1.Authentication.provider && authentication_1.Authentication.provider.isLoggedIn();
  return {
    accountName: accountName,
    doAutomaticLogin: isLoggedIn,
    isLogoutRequired: isLoginIncomplete
  };
};

exports.actions = {
  login: login_async_action_1.loginAsyncAction,
  logout: logout_async_action_1.logoutAsyncAction
};
exports.LoginPageConnected = react_redux_1.connect(exports.mapStateToProps, exports.actions)(login_page_1.LoginPage);