"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var PackageTypeFormatter = function () {
  function PackageTypeFormatter() {}

  PackageTypeFormatter.format = function (typeCode, packageTypes) {
    if (!typeCode) {
      return '?';
    }

    var packageType = PackageTypeFormatter.getPackageType(typeCode, packageTypes);
    return packageType ? packageType.description : typeCode;
  };

  PackageTypeFormatter.getPackageType = function (typeCode, packageTypes) {
    if (packageTypes === void 0) {
      packageTypes = [];
    }

    return packageTypes.find(function (packageType) {
      return packageType.code === typeCode;
    });
  };

  return PackageTypeFormatter;
}();

exports.PackageTypeFormatter = PackageTypeFormatter;