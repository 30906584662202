"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var theme_1 = require("../theme");

var busyOverlayViewStyle = {
  backgroundColor: theme_1.GreyScale.lightest,
  bottom: 0,
  cursor: 'wait',
  left: 0,
  opacity: 0.3,
  position: 'fixed',
  right: 0,
  top: 0,
  zIndex: 999
};
exports.busyOverlaySpinnerColor = 'white';
var busyOverlaySpinnerViewStyle = {
  backgroundColor: theme_1.PurpleScale.darkest,
  borderRadius: 4,
  height: '4rem',
  left: '50%',
  opacity: 0.8,
  position: 'absolute',
  top: '25%',
  transform: [{
    translateX: '-50%'
  }],
  width: '4rem',
  zIndex: 1000
};
exports.busyOverlayStyle = {
  busyOverlaySpinnerViewStyle: busyOverlaySpinnerViewStyle,
  busyOverlayViewStyle: busyOverlayViewStyle
};