"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var native_base_1 = require("native-base");

var react_1 = __importStar(require("react"));

var react_native_1 = require("react-native");

var autocomplete_content_1 = require("../../../../theming/pricing-system/autocomplete.content");

var autocomplete_style_1 = require("../../../../theming/pricing-system/autocomplete.style");

var theme_pricing_system_1 = require("../../../../theming/theme.pricing-system");

var field_1 = require("../../layout/field/field");

var label_1 = require("../../text/label/label");

var static_text_1 = require("../../text/static-text/static-text");

var autocomplete_item_1 = require("./autocomplete-item");

var contentContainerViewStyle = {
  flexShrink: 0
};

var AutocompleteField = function (_super) {
  __extends(AutocompleteField, _super);

  function AutocompleteField() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.state = {
      filter: '',
      showSelectList: false
    };
    _this.inputRef = react_1.createRef();

    _this.clear = function () {
      _this.resetTimer();

      _this.setState({
        errorMessage: undefined,
        filter: '',
        showSelectList: false
      });

      if (_this.props.findItems) {
        _this.props.findItems('');
      }
    };

    _this.focus = function () {
      var inputRef = _this.inputRef.current;

      if (inputRef) {
        inputRef.focus();
      }
    };

    _this.validate = function () {
      if (_this.state.errorMessage) {
        return;
      }

      if (!_this.props.data.items.length || _this.state.showSelectList) {
        var errorMessage = _this.getInputInvalidErrorMessage('');

        _this.setState({
          errorMessage: errorMessage
        });
      }
    };

    _this.renderItem = function (itemWithIndex) {
      var item = itemWithIndex.item,
          index = itemWithIndex.index;

      var onPress = function onPress() {
        return _this.onAutocompleteItemSelect(item);
      };

      var renderer = _this.props.renderItem;

      if (!renderer) {
        renderer = function renderer(i) {
          return i.toString();
        };
      }

      return react_1.default.createElement(autocomplete_item_1.AutocompleteItem, {
        onPress: onPress,
        key: index,
        index: index,
        item: item,
        filter: _this.state.filter,
        renderItem: renderer,
        areEqual: _this.props.areItemsEqual
      });
    };

    _this.keyExtractor = function (item, index) {
      if (_this.props.keyExtractor) {
        return _this.props.keyExtractor(item);
      }

      return index.toString();
    };

    _this.renderListEmpty = function () {
      var filter = _this.state.filter;

      if (!filter) {
        return null;
      }

      var message = '';

      if (filter.length < _this.minChars) {
        message = autocomplete_content_1.AutocompleteContent.specifyMinChars(_this.minChars);
      } else if (_this.props.isBusy || _this.isDebouncing) {
        message = autocomplete_content_1.AutocompleteContent.searching;
      } else {
        message = _this.getPropMessageOrDefault(autocomplete_content_1.AutocompleteContent.noItemsFound(_this.state.filter), _this.props.noItemsMessage, _this.state.filter);
      }

      return react_1.default.createElement(static_text_1.StaticText, {
        textStyle: theme_pricing_system_1.pricingStyles.autocompleteNoItemsTextStyle,
        text: message
      });
    };

    _this.renderFooter = function () {
      if (_this.props.isBusy || _this.state.filter.length < _this.minChars) {
        return null;
      }

      var returnedItems = _this.props.data.items.length;
      var totalItems = _this.props.data.totalItems || returnedItems;
      var remainingItems = totalItems - returnedItems;

      var message = _this.getPropMessageOrDefault(autocomplete_content_1.AutocompleteContent.moreItemsFound(remainingItems), _this.props.moreItemsMessage, remainingItems);

      if (remainingItems > 0) {
        return react_1.default.createElement(static_text_1.StaticText, {
          textStyle: theme_pricing_system_1.pricingStyles.autocompleteMoreItemsTextStyle,
          text: message
        });
      }

      return null;
    };

    _this.onFilterChange = function (reactText) {
      var text = reactText.toString();

      _this.setState({
        errorMessage: undefined,
        filter: text,
        showSelectList: !!text
      }, function () {
        return _this.runTimeoutUpdate(text);
      });

      var selectedItem = _this.getSelectedItem(text);

      _this.sendSelectEvent(selectedItem);
    };

    _this.onFocus = function () {
      _this.setState({
        showSelectList: _this.props.data.items.length > 0
      });
    };

    _this.onAutocompleteItemSelect = function (selectedItem) {
      _this.setState({
        errorMessage: undefined,
        filter: _this.getFilteredPropertyValue(selectedItem, _this.state.filter),
        showSelectList: false
      });

      _this.sendSelectEvent(selectedItem);
    };

    _this.sendSelectEvent = function (selectedItem) {
      var onSelect = _this.props.onSelect;

      if (!onSelect) {
        return;
      }

      onSelect(selectedItem);
    };

    _this.resetTimer = function () {
      var timerId = _this.state.timerId;

      if (timerId) {
        clearTimeout(timerId);

        _this.setState({
          timerId: undefined
        });
      }
    };

    _this.notify = function (filter) {
      var findItems = _this.props.findItems;
      var minChars = _this.minChars;

      if (filter.length < minChars) {
        filter = '';
      }

      if (findItems) {
        findItems(filter);
      }

      _this.setState({
        timerId: undefined
      });
    };

    _this.runTimeoutUpdate = function (filter) {
      var debounceTimeout = _this.props.debounceTimeout;

      if (!debounceTimeout) {
        return;
      }

      _this.resetTimer();

      var timerId = setTimeout(function () {
        return _this.notify(filter);
      }, debounceTimeout);

      _this.setState({
        timerId: timerId
      });
    };

    return _this;
  }

  AutocompleteField.prototype.componentWillUnmount = function () {
    this.resetTimer();
  };

  AutocompleteField.prototype.render = function () {
    var _a = this.props,
        _b = _a.label,
        label = _b === void 0 ? '' : _b,
        viewStyle = _a.viewStyle;
    return react_1.default.createElement(field_1.Field, {
      errorMessage: this.state.errorMessage,
      viewStyle: viewStyle
    }, react_1.default.createElement(label_1.Label, {
      label: label
    }, this.renderInputComponent(), this.renderSelectList()));
  };

  AutocompleteField.prototype.renderInputComponent = function () {
    var keyboardType = this.props.keyboardType || 'default';
    var textStyle = !this.state.errorMessage ? theme_pricing_system_1.pricingStyles.priceLookupTextInputStyle : theme_pricing_system_1.pricingStyles.priceLookupTextInputErrorStyle;
    var propsTextInputWidth = this.props.textInputWidth;
    var textInputWidthStyle = propsTextInputWidth ? {
      width: propsTextInputWidth
    } : {};
    return react_1.default.createElement(react_native_1.View, {
      style: theme_pricing_system_1.pricingStyles.autocompleteInputContainerTextStyle
    }, react_1.default.createElement(react_native_1.TextInput, {
      ref: this.inputRef,
      autoFocus: this.props.autoFocus,
      keyboardType: keyboardType,
      style: [textStyle, textInputWidthStyle],
      onChangeText: this.onFilterChange,
      onFocus: this.onFocus,
      value: this.state.filter,
      placeholder: this.props.placeholder,
      editable: !this.props.isDisabled
    }), this.renderBusySpinner());
  };

  AutocompleteField.prototype.renderSelectList = function () {
    if (!this.state.showSelectList || !this.state.filter) {
      return null;
    }

    var items = this.props.data.items;
    var minWidth = this.props.textInputWidth || '100%';
    var selectListStyle = {
      minWidth: minWidth
    };
    return react_1.default.createElement(react_native_1.FlatList, {
      style: [theme_pricing_system_1.pricingStyles.autocompleteListViewStyle, selectListStyle],
      contentContainerStyle: contentContainerViewStyle,
      data: items,
      renderItem: this.renderItem,
      keyExtractor: this.keyExtractor,
      ListEmptyComponent: this.renderListEmpty,
      ListFooterComponent: this.renderFooter,
      initialNumToRender: this.props.initialNumToRender
    });
  };

  AutocompleteField.prototype.renderBusySpinner = function () {
    if (!this.props.isBusy) {
      return null;
    }

    return react_1.default.createElement(react_native_1.Text, {
      style: theme_pricing_system_1.pricingStyles.autocompleteSpinnerTextStyle
    }, react_1.default.createElement(native_base_1.Spinner, {
      color: autocomplete_style_1.autocompleteSpinnerColor,
      size: autocomplete_style_1.autocompleteSpinnerSize,
      style: theme_pricing_system_1.pricingStyles.autocompleteSpinnerViewStyle
    }));
  };

  AutocompleteField.prototype.getSelectedItem = function (filter) {
    var _this = this;

    var items = this.props.data.items;
    return items.find(function (item) {
      return _this.getFilteredPropertyValue(item, filter) === filter;
    });
  };

  AutocompleteField.prototype.getFilteredPropertyValue = function (item, filter) {
    if (this.props.filterExtractor) {
      return this.props.filterExtractor(item, filter);
    }

    if (this.props.keyExtractor) {
      return this.props.keyExtractor(item);
    }

    if (!('key' in item)) {
      throw Error('No filter or key extractor provided and item contains no "key" property!');
    }

    return item.key;
  };

  AutocompleteField.prototype.getInputInvalidErrorMessage = function (filter) {
    return this.getPropMessageOrDefault(autocomplete_content_1.AutocompleteContent.inputInvalid, this.props.invalidInputMessage, filter);
  };

  AutocompleteField.prototype.getPropMessageOrDefault = function (defaultMessage, propsMessage, value) {
    if (!propsMessage) {
      return defaultMessage;
    }

    if (typeof propsMessage === 'string') {
      return propsMessage;
    }

    return propsMessage(value);
  };

  Object.defineProperty(AutocompleteField.prototype, "isDebouncing", {
    get: function get() {
      return this.state.timerId;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(AutocompleteField.prototype, "minChars", {
    get: function get() {
      return this.props.minChars || 1;
    },
    enumerable: true,
    configurable: true
  });
  return AutocompleteField;
}(react_1.default.PureComponent);

exports.AutocompleteField = AutocompleteField;