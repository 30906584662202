"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function formatPhoneNumber(phoneNumber) {
  phoneNumber = phoneNumber.replace(/\D/g, '');

  if (phoneNumber.length === 10) {
    var formattedNum = '(' + phoneNumber[0] + phoneNumber[1] + phoneNumber[2] + ') ' + phoneNumber[3] + phoneNumber[4] + phoneNumber[5] + '-' + phoneNumber[6] + phoneNumber[7] + phoneNumber[8] + phoneNumber[9];
    return formattedNum;
  } else if (phoneNumber.length === 11) {
    var formattedNum = '(' + phoneNumber[1] + phoneNumber[2] + phoneNumber[3] + ') ' + phoneNumber[4] + phoneNumber[5] + phoneNumber[6] + '-' + phoneNumber[7] + phoneNumber[8] + phoneNumber[9] + phoneNumber[10];
    return formattedNum;
  }

  return phoneNumber;
}

exports.formatPhoneNumber = formatPhoneNumber;