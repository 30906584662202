"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_redux_1 = require("react-redux");

var error_message_hide_action_1 = require("../../../../experiences/pharmacy-pricing-experience/store/common/status/actions/error-message-hide.action");

var info_message_hide_action_1 = require("../../../../experiences/pharmacy-pricing-experience/store/common/status/actions/info-message-hide.action");

var pharmacy_group_select_action_1 = require("../../../../experiences/pharmacy-pricing-experience/store/price-lookup/actions/pharmacy-group-select.action");

var page_1 = require("./page");

exports.mapStateToProps = function (state, ownProps) {
  return __assign({
    currentGroup: state.priceLookup.currentGroup,
    error: state.status.error,
    infoMessage: state.status.infoMessage,
    showBusy: state.busy.showBusy,
    showRulesTab: state.features.showrules
  }, ownProps);
};

exports.actions = {
  onErrorClose: error_message_hide_action_1.errorMessageHideAction,
  onGroupSelect: pharmacy_group_select_action_1.pharmacyGroupSelectAction,
  onMessageFaded: info_message_hide_action_1.infoMessageHideAction
};
exports.PageConnected = react_redux_1.connect(exports.mapStateToProps, exports.actions, undefined, {
  forwardRef: true
})(page_1.Page);