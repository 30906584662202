"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var NumericValidator = function () {
  function NumericValidator() {}

  NumericValidator.isWholeNumber = function (text) {
    if (text === void 0) {
      text = '';
    }

    return /^\d+$/.test(text);
  };

  return NumericValidator;
}();

exports.NumericValidator = NumericValidator;