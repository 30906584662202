"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var redux_1 = require("redux");

var busy_reducer_1 = require("./common/busy/busy-reducer");

var status_reducer_1 = require("./common/status/status.reducer");

var config_state_reducer_1 = require("./config/config-state-reducer");

var features_state_reducer_1 = require("./features/features-state-reducer");

var navigation_reducer_1 = require("./navigation/navigation-reducer");

var scene_navigation_reducer_1 = require("./navigation/scene-navigation.reducer");

var pharmacy_pricing_reducer_1 = require("./pharmacy-pricing/pharmacy-pricing-reducer");

var price_lookup_reducer_1 = require("./price-lookup/price-lookup.reducer");

exports.rootReducer = redux_1.combineReducers({
  busy: busy_reducer_1.busyReducer,
  config: config_state_reducer_1.configStateReducer,
  features: features_state_reducer_1.featuresStateReducer,
  nav: navigation_reducer_1.navigationReducer,
  navigation: scene_navigation_reducer_1.sceneNavigationReducer,
  pharmacyPricing: pharmacy_pricing_reducer_1.pharmacyPricingReducer,
  priceLookup: price_lookup_reducer_1.priceLookupReducer,
  status: status_reducer_1.statusReducer
});