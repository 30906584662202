"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var theme_1 = require("../theme");

var base_text_style_1 = require("./base-text.style");

var buttonTextStyle = __assign(__assign({}, base_text_style_1.baseTextStyle), {
  color: '#fff',
  textAlign: 'center'
});

var buttonBaseIconTextStyle = __assign(__assign({}, base_text_style_1.baseTextStyle), {
  textAlign: 'center'
});

var buttonIconTextStyle = __assign(__assign({}, buttonBaseIconTextStyle), {
  color: theme_1.PurpleScale.darkest
});

var buttonDisabledIconTextStyle = __assign(__assign({}, buttonBaseIconTextStyle), {
  color: theme_1.GreyScale.regular
});

var buttonBaseViewStyle = {
  borderRadius: 4,
  marginLeft: '.2em',
  marginRight: '.2em',
  minWidth: '6em',
  paddingBottom: '.4em',
  paddingLeft: '.4em',
  paddingRight: '.4em',
  paddingTop: '.4em'
};

var buttonPrimaryViewStyle = __assign(__assign({}, buttonBaseViewStyle), {
  backgroundColor: theme_1.PurpleScale.darkest
});

var buttonSecondaryViewStyle = __assign(__assign({}, buttonBaseViewStyle), {
  backgroundColor: theme_1.GreyScale.lighterDark
});

var buttonDisabledViewStyle = __assign(__assign({}, buttonBaseViewStyle), {
  backgroundColor: '#d3d3d3'
});

var buttonIconViewStyle = __assign(__assign({}, buttonPrimaryViewStyle), {
  backgroundColor: 'inherit',
  minWidth: 'auto'
});

exports.buttonStyle = {
  buttonDisabledIconTextStyle: buttonDisabledIconTextStyle,
  buttonDisabledViewStyle: buttonDisabledViewStyle,
  buttonIconTextStyle: buttonIconTextStyle,
  buttonIconViewStyle: buttonIconViewStyle,
  buttonPrimaryViewStyle: buttonPrimaryViewStyle,
  buttonSecondaryViewStyle: buttonSecondaryViewStyle,
  buttonTextStyle: buttonTextStyle
};