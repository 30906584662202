"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Symbols;

(function (Symbols) {
  Symbols["bullet"] = "\u2022";
  Symbols["tradeMark"] = "\u2122";
})(Symbols = exports.Symbols || (exports.Symbols = {}));