"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var base_text_style_1 = require("./base-text.style");

var nativeLabelAboveViewStyle = {
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column'
};
var nativeLabelLeftViewStyle = {
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'row'
};
var nativeLabelRightViewStyle = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row-reverse'
};

var nativeLabelAboveTextStyle = __assign(__assign({}, base_text_style_1.baseTextStyle), {
  marginBottom: '.1em'
});

var nativeLabelRightTextStyle = __assign(__assign({}, base_text_style_1.baseTextStyle), {
  marginLeft: '.4em'
});

var nativeLabelLeftTextStyle = __assign(__assign({}, base_text_style_1.baseTextStyle), {
  marginRight: '.4em',
  marginTop: '.2em'
});

exports.nativeLabelStyle = {
  nativeLabelAboveTextStyle: nativeLabelAboveTextStyle,
  nativeLabelAboveViewStyle: nativeLabelAboveViewStyle,
  nativeLabelLeftTextStyle: nativeLabelLeftTextStyle,
  nativeLabelLeftViewStyle: nativeLabelLeftViewStyle,
  nativeLabelRightTextStyle: nativeLabelRightTextStyle,
  nativeLabelRightViewStyle: nativeLabelRightViewStyle
};