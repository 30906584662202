"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var authenticationApi = {
  env: {
    host: 'login.microsoftonline.com',
    port: '',
    protocol: 'https',
    version: ''
  },
  paths: {
    login: '/:tenantId'
  }
};
var domainDataSearchApi = {
  env: {
    host: '',
    port: '',
    protocol: 'https',
    version: '/api/data'
  },
  paths: {
    drugGet: "/drugs/:ndc",
    drugSearch: "/drugs?group=:groupNcpdp&filter=:filter&maxResults=:maxResults",
    getAllDrugForms: '/forms',
    getAllPackageTypes: '/packages'
  }
};
var userManagementApi = {
  env: {
    host: '',
    port: '',
    protocol: 'https',
    version: '/api/user'
  },
  paths: {
    generatePassword: '/password?userId=:userOid',
    getPharmacies: '/pharmacies?groupId=:groupOid',
    getPharmacyGroup: '/group',
    getPharmacyGroups: '/groups'
  }
};
var transactionDetailsApi = {
  env: {
    host: 'epirus.ltbx.com.br',
    port: '',
    protocol: 'https',
    version: ''
  },
  paths: {
    detailsUrl: '/transactions/:transactionId/function'
  }
};
var pricingExperienceApi = {
  env: {
    host: '',
    port: '',
    protocol: 'https',
    version: '/pricing/v2'
  },
  paths: {
    priceLookup: '/lookup'
  }
};
exports.PricingExperienceConfig = {
  apis: {
    authenticationApi: authenticationApi,
    domainDataSearchApi: domainDataSearchApi,
    pricingExperienceApi: pricingExperienceApi,
    transactionDetailsApi: transactionDetailsApi,
    userManagementApi: userManagementApi
  },
  auth: {
    clientId: '',
    tenantId: ''
  },
  currentDate: new Date(),
  drugSearchDebounceMs: 500,
  drugSearchInitialNumToRender: 100,
  drugSearchMaxResults: 100,
  drugSearchMinChars: 3,
  location: undefined,
  subscriptionId: '',
  telemetry: {
    instrumentationKey: '',
    serviceName: 'pricingexperience-web'
  },
  transactionDetailsWaitSec: 5
};