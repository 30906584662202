"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var status_action_keys_1 = require("./status-action-keys");

exports.errorMessageHideAction = function () {
  return {
    type: status_action_keys_1.StatusActionKeys.STATUS_ERROR_MESSAGE_HIDE
  };
};