"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_redux_1 = require("react-redux");

var wait_for_transaction_details_async_action_1 = require("../../store/price-lookup/actions/wait-for-transaction-details-async.action");

var price_lookup_results_panel_1 = require("./price-lookup-results-panel");

exports.mapStateToProps = function (state) {
  var priceLookupState = state.priceLookup;
  return {
    isPriceLookupInProgress: priceLookupState.isPriceLookupInProgress,
    isWaitingForTransactionDetails: priceLookupState.isWaitingForTransactionDetails,
    priceLookupResult: priceLookupState.priceLookupResult,
    showTransactionDetails: state.features.showtrx && priceLookupState.authorization.isAdmin,
    transactionDetailsApi: state.config.apis.transactionDetailsApi
  };
};

exports.actions = {
  waitForDetails: wait_for_transaction_details_async_action_1.waitForTransactionDetailsAsyncAction
};
exports.PriceLookupResultsPanelConnected = react_redux_1.connect(exports.mapStateToProps, exports.actions)(price_lookup_results_panel_1.PriceLookupResultsPanel);