"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var symbols_1 = require("../../utils/symbols");

var formLabel = 'Form';
var noDrugSpecified = 'Please specify a drug.';
var notApplicable = 'n/a';
exports.nonZeroWholeNumberRequired = 'Please specify a non-zero value.';
var groupLabel = 'Group:';
var DrugTypeName;

(function (DrugTypeName) {
  DrugTypeName["brand"] = "Brand";
  DrugTypeName["generic"] = "Generic";
})(DrugTypeName = exports.DrugTypeName || (exports.DrugTypeName = {}));

exports.PasswordGenerateWarningPromptContent = {
  cancelButtonText: 'Cancel',
  paragraph1: 'Generating a pharmacy password will reset the current pharmacy password.',
  paragraph2: 'Please confirm that you wish to perform a password reset.',
  resetPasswordButtonText: 'Reset Password'
};
exports.PriceLookupDrugInfoPanelContent = {
  drugCodesLabel: 'Drug Codes',
  drugNameLabel: 'Drug Name',
  drugTypeLabel: 'Drug Type',
  formLabel: formLabel,
  heading: 'Results',
  ndcLabel: 'NDC',
  noDrugSpecified: noDrugSpecified,
  notApplicable: notApplicable,
  packageQuantityLabel: 'Package Quantity',
  packageTypeLabel: 'Package Type',
  strengthLabel: 'Strength'
};
exports.PriceLookupResultsPanelContent = {
  calculating: 'Calculating...',
  heading: 'Price',
  ndcLabel: 'NDC',
  priceLabel: 'Price',
  specifyAllRequiredInformation: 'Please specify all drug information and press "Submit".',
  transactionIdLabel: 'Transaction Id'
};
exports.PricingTableEmptyText = 'No items available.';
exports.SelectGroupMessageContent = {
  message: 'Please select a pharmacy group.'
};
exports.TextInputFieldContent = {
  invalidInputMessage: 'Please specify a valid value.'
};
exports.DaysSupplyFieldContent = {
  invalidInputMessage: exports.nonZeroWholeNumberRequired,
  label: 'Days Supply'
};
exports.GroupPickerFieldContent = {
  label: groupLabel
};
exports.GroupStaticFieldContent = {
  label: groupLabel
};
exports.AdminTabsContent = {
  pharmacyManagementTabText: 'Pharmacy Management',
  priceLookupTabText: 'Price Lookup'
};
exports.WarningPromptContent = {
  promptTitle: 'Warning!'
};
exports.passwordPlaceholder = symbols_1.Symbols.bullet.repeat(8);