"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_navigation_redux_helpers_1 = require("react-navigation-redux-helpers");

exports.navigationMiddlewareKey = 'guest-experience';
exports.navigationMiddleware = react_navigation_redux_helpers_1.createReactNavigationReduxMiddleware(exports.navigationMiddlewareKey, function (state) {
  return state.nav;
});