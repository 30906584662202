"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function applyProcessSwitches(features, env) {
  Object.keys(features).forEach(function (key) {
    var envKey = "REACT_APP_FEATURE_" + key.toUpperCase();
    var envValue = env[envKey];

    if (envValue === 'true' || envValue === 'false') {
      features[key] = envValue === 'true';
    }
  });
}

exports.applyProcessSwitches = applyProcessSwitches;

function applyQuerySwitches(features, query) {
  var flagsets = query.match(/\A?f=([^&]+)&*/i);

  if (flagsets && flagsets.length === 2) {
    var flagset = flagsets[1];
    var flags = flagset.split(',').map(function (f) {
      return f.split(':');
    });
    flags.forEach(function (f) {
      if (Array.isArray(f) && f.length === 2) {
        var key = f[0];
        var value = f[1];

        if (value === '0' || value === '1') {
          features[key] = value === '1';
        }
      }
    });
  }
}

exports.applyQuerySwitches = applyQuerySwitches;