"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var theme_1 = require("../theme");

var base_text_style_1 = require("./base-text.style");

var panelPadding = '.5em';
var messagePanelHeadingTextStyle = {
  textAlign: 'center'
};
var baseMessagePanelViewStyle = {
  borderRadius: 4,
  borderStyle: 'solid',
  borderWidth: 1,
  paddingBottom: panelPadding,
  paddingLeft: panelPadding,
  paddingRight: panelPadding,
  paddingTop: panelPadding
};

var messagePanelInfoViewStyle = __assign(__assign({}, baseMessagePanelViewStyle), {
  backgroundColor: theme_1.GreyScale.lighter,
  borderColor: theme_1.GreyScale.light
});

var messagePanelSuccessViewStyle = __assign(__assign({}, baseMessagePanelViewStyle), {
  backgroundColor: theme_1.GreenScale.lightest,
  borderColor: theme_1.GreenScale.darkest
});

var messagePanelShadowAddViewStyle = {
  shadowColor: theme_1.GreyScale.light,
  shadowOffset: {
    width: 5,
    height: 5
  },
  shadowRadius: 5
};

var baseMessagePanelTextStyle = __assign(__assign({}, base_text_style_1.baseTextStyle), {
  flexGrow: 1
});

var messagePanelInfoTextStyle = baseMessagePanelTextStyle;

var messagePanelSuccessTextStyle = __assign(__assign({}, baseMessagePanelTextStyle), {
  color: theme_1.GreenScale.darkest
});

exports.messagePanelStyle = {
  messagePanelHeadingTextStyle: messagePanelHeadingTextStyle,
  messagePanelInfoTextStyle: messagePanelInfoTextStyle,
  messagePanelInfoViewStyle: messagePanelInfoViewStyle,
  messagePanelShadowAddViewStyle: messagePanelShadowAddViewStyle,
  messagePanelSuccessTextStyle: messagePanelSuccessTextStyle,
  messagePanelSuccessViewStyle: messagePanelSuccessViewStyle
};
exports.messagePanelFadeDelay = 2000;
exports.messagePanelFadeDuration = 2000;