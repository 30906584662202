"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_redux_1 = require("react-redux");

var get_pharmacies_async_action_1 = require("../../store/price-lookup/actions/get-pharmacies-async.action");

var password_clear_action_1 = require("../../store/price-lookup/actions/password-clear.action");

var password_generate_async_action_1 = require("../../store/price-lookup/actions/password-generate-async.action");

var pharmacy_management_page_1 = require("./pharmacy-management-page");

exports.mapStateToProps = function (state) {
  return {
    currentGroup: state.priceLookup.currentGroup,
    doPharmaciesRefresh: state.priceLookup.doPharmaciesRefresh,
    pharmacies: state.priceLookup.pharmacies,
    pharmacyPassword: state.priceLookup.pharmacyPassword
  };
};

exports.actions = {
  passwordClear: password_clear_action_1.passwordClearAction,
  passwordGenerate: password_generate_async_action_1.passwordGenerateAsyncAction,
  pharmaciesGet: get_pharmacies_async_action_1.getPharmaciesAsyncAction
};
exports.PharmacyManagementPageConnected = react_redux_1.connect(exports.mapStateToProps, exports.actions)(pharmacy_management_page_1.PharmacyManagementPage);