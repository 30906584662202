// Copyright 2020 Prescryptive Health, inc

import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import {
  ILogger,
  Logger,
} from 'prescryptive-ux-common/lib/experiences/pharmacy-pricing-experience/logging/logger';
import {
  PricingExperienceConfig,
  PricingExperienceConfigApiNames,
} from 'prescryptive-ux-common/lib/experiences/pharmacy-pricing-experience/pricing-experience-config';
import { IConfigState } from 'prescryptive-ux-common/lib/utils/api.helper';
import { TelemetryService } from './telemetry-service';

export const reactPlugIn = new ReactPlugin();

export function initializeTelemetry(
  config: IConfigState<
    PricingExperienceConfigApiNames
  > = PricingExperienceConfig,
  logger: ILogger = Logger
) {
  const browserHistory = createBrowserHistory({ basename: '' });

  const telemetryConfig = config.telemetry;
  const telemetryService = new TelemetryService(
    telemetryConfig.instrumentationKey,
    browserHistory,
    reactPlugIn
  );

  telemetryService.appInsights.addTelemetryInitializer(telemetryItem => {
    telemetryItem.tags!['ai.cloud.role'] = telemetryConfig.serviceName;
  });

  setAjaxFilterInitializer(telemetryService, config);

  logger.logException = telemetryService.trackException;
  logger.logEvent = telemetryService.trackEvent;
}

function setAjaxFilterInitializer(
  telemetryService: TelemetryService,
  config: IConfigState<PricingExperienceConfigApiNames>
) {
  function ajaxFilter(telemetryItem: ITelemetryItem): boolean {
    const baseData = telemetryItem.baseData;

    if (baseData && baseData.type === 'Ajax') {
      const target: string = baseData.target;
      const authHost = config.apis.authenticationApi.env.host;

      // Track only authentication AJAX calls.
      return target.search(authHost) !== -1;
    }

    return true;
  }

  telemetryService.appInsights.addTelemetryInitializer(ajaxFilter);
}
