"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var mockRule = {
  address: '0',
  brandCode: {
    B: true,
    G: true,
    T: true
  },
  daysSupply: '30',
  dispensingFee: 5,
  effectiveDate: new Date(2019, 8, 1),
  errors: {
    effectiveDate: false
  },
  multiSourceCode: {
    M: false,
    N: false,
    O: true,
    Y: false
  },
  offsetPercentage: 0.05,
  offsetType: 'AWP',
  ruleName: 'T,G,B,O',
  template: '-Offset % + $DF'
};
var mockRule2 = {
  address: '1',
  brandCode: {
    B: false,
    G: true,
    T: true
  },
  daysSupply: '30',
  dispensingFee: 5,
  effectiveDate: new Date(2019, 8, 1),
  errors: {
    effectiveDate: false
  },
  multiSourceCode: {
    M: false,
    N: false,
    O: true,
    Y: false
  },
  offsetPercentage: 0.05,
  offsetType: 'AWP',
  ruleName: 'T,G,O',
  template: '-Offset % + $DF'
};
exports.mockRuleList = [mockRule, mockRule2];