"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var EventPropertiesFactory = function () {
  function EventPropertiesFactory() {}

  EventPropertiesFactory.statusProperties = function (status, authProvider) {
    var userName = authProvider ? authProvider.getAccountName() : undefined;
    var roles = authProvider ? authProvider.getRoles() : undefined;
    return {
      roles: roles,
      status: status,
      userName: userName
    };
  };

  EventPropertiesFactory.priceLookupProperties = function (status, lookupParams, groupNcpdp, authProvider, priceInDollars) {
    var userName = authProvider ? authProvider.getAccountName() : undefined;
    var roles = authProvider ? authProvider.getRoles() : undefined;
    return {
      groupNcpdp: groupNcpdp,
      lookupParams: lookupParams,
      price: priceInDollars,
      roles: roles,
      status: status,
      userName: userName
    };
  };

  return EventPropertiesFactory;
}();

exports.EventPropertiesFactory = EventPropertiesFactory;