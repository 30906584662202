"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_navigation_1 = require("react-navigation");

var constants_1 = require("../../theming/constants");

var handleBack = function handleBack() {
  if (exports.PricingExperienceNavigation.store.getState().navigation.allowBack) {
    exports.PricingExperienceNavigation.store.dispatch(react_navigation_1.NavigationActions.back());
  }
};

exports.PricingExperienceNavigation = {
  handleBack: handleBack,
  store: {
    dispatch: function dispatch() {
      throw new Error(constants_1.ErrorConstants.errorStroreIsNotAssignedForPricingExperienceNavigation);
    },
    getState: function getState() {
      throw new Error(constants_1.ErrorConstants.errorStroreIsNotAssignedForPricingExperienceNavigation);
    }
  }
};