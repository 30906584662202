"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ErrorPanelContent = {
  closeIconAccessibilityText: 'Close error message.',
  contactSupport: 'Please try again.',
  contextId: 'ID:',
  defaultHeading: 'There was an error processing your request.'
};