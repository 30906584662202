"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_redux_1 = require("react-redux");

var price_lookup_drug_info_panel_1 = require("./price-lookup-drug-info-panel");

exports.mapStateToProps = function (state) {
  var priceLookupState = state.priceLookup;
  return {
    drug: priceLookupState.drugGetResult,
    drugForms: priceLookupState.drugForms,
    packageTypes: priceLookupState.packageTypes
  };
};

exports.PriceLookupDrugInfoPanelConnected = react_redux_1.connect(exports.mapStateToProps)(price_lookup_drug_info_panel_1.PriceLookupDrugInfoPanel);