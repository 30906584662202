"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DrugSearchFieldContent = {
  helpButtonIconText: 'Show drug search examples.',
  invalidInputMessage: 'Please specify a valid drug name or NDC.',
  label: 'Drug Name or NDC',
  noMatchesMessage: function noMatchesMessage(filter) {
    return "No drugs found matching \"" + filter + "\".";
  },
  placeholder: 'Enter drug name or NDC here.'
};