"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_redux_1 = require("react-redux");

var pharmacy_pricing_reducer_actions_1 = require("../store/pharmacy-pricing/pharmacy-pricing-reducer.actions");

var pharmacy_pricing_screen_1 = require("./pharmacy-pricing-screen");

exports.mapStateToProps = function (state) {
  if (!state.pharmacyPricing) {
    throw new Error('Missing state.pharmacyPricing');
  }

  var selectedPharmacy = state.pharmacyPricing.selectedPharmacy;
  var pharmacies = state.pharmacyPricing.pharmacies.map(function (p) {
    return {
      id: p.address,
      name: p.name
    };
  }) || [];
  var pricingRules = selectedPharmacy && selectedPharmacy.pricingRules.map(function (r) {
    return {
      id: r.address,
      ruleName: r.ruleName,
      template: r.template
    };
  }) || [];
  return {
    pharmacies: pharmacies,
    pricingRules: pricingRules,
    selectedPharmacyId: selectedPharmacy && selectedPharmacy.address,
    selectedRuleId: state.pharmacyPricing.selectedPricingRule && state.pharmacyPricing.selectedPricingRule.address || undefined
  };
};

var actions = {
  pricingPharmacyGroupSelectAction: pharmacy_pricing_reducer_actions_1.pricingPharmacyGroupSelectAction,
  pricingRuleSelectAction: pharmacy_pricing_reducer_actions_1.pricingRuleSelectAction
};
exports.ConnectedPharmacyPricingScreen = react_redux_1.connect(exports.mapStateToProps, actions)(pharmacy_pricing_screen_1.PharmacyPricingScreen);