"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_redux_1 = require("react-redux");

var pharmacy_pricing_reducer_actions_1 = require("../../../experiences/pharmacy-pricing-experience/store/pharmacy-pricing/pharmacy-pricing-reducer.actions");

var selector_1 = require("./selector");

var changeDaysSupplyTypePropertyAction = pharmacy_pricing_reducer_actions_1.pricingRuleDetailsSetSelectorPropertyActionBuilder('daysSupply');
var selectorActionProps = {
  onChangeItem: changeDaysSupplyTypePropertyAction
};

exports.mapStateToProps = function (state) {
  return __assign(__assign(__assign({}, state), selectorActionProps), {
    selectorId: 'daysSupply',
    selectorItemList: [{
      label: '30',
      id: '30'
    }, {
      label: '90',
      id: '90'
    }],
    selectorLabel: 'Days Supply'
  });
};

var actions = selectorActionProps;
exports.ConnectedDaysSupplySelector = react_redux_1.connect(exports.mapStateToProps, actions)(selector_1.Selector);