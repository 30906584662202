"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_native_1 = require("react-native");

exports.LocalDimensions = {
  maxWidth: 800,
  width: react_native_1.Platform.select({
    ios: react_native_1.Dimensions.get('window').width,
    android: react_native_1.Dimensions.get('window').width,
    web: react_native_1.Dimensions.get('window').width
  }),
  height: react_native_1.Platform.select({
    web: react_native_1.Dimensions.get('window').height
  })
};
exports.DesktopDimensions = {
  width: react_native_1.Dimensions.get('window').width,
  height: react_native_1.Dimensions.get('window').height
};
var GreenScale;

(function (GreenScale) {
  GreenScale["lightest"] = "#e5f2e5";
  GreenScale["lighter"] = "#dcefde";
  GreenScale["light"] = "#44b356";
  GreenScale["dark"] = "#7bad2d";
  GreenScale["darker"] = "#39b54a";
  GreenScale["darkest"] = "#008000";
})(GreenScale = exports.GreenScale || (exports.GreenScale = {}));

var PurpleScale;

(function (PurpleScale) {
  PurpleScale["lightest"] = "#f5f3ff";
  PurpleScale["lighter"] = "#f2e9f4";
  PurpleScale["regular"] = "#b3a2d2";
  PurpleScale["darker"] = "#88498f";
  PurpleScale["darkest"] = "#6240a3";
})(PurpleScale = exports.PurpleScale || (exports.PurpleScale = {}));

var BlueScale;

(function (BlueScale) {
  BlueScale["lighter"] = "#f0f5f7";
  BlueScale["light"] = "#eaf8fc";
  BlueScale["darker"] = "#2cbde5";
  BlueScale["darkest"] = "#1a7189";
})(BlueScale = exports.BlueScale || (exports.BlueScale = {}));

var GreyScale;

(function (GreyScale) {
  GreyScale["lightest"] = "#ffffff";
  GreyScale["lighter"] = "#e6e6e6";
  GreyScale["light"] = "#cccccc";
  GreyScale["regular"] = "#999999";
  GreyScale["lighterDark"] = "#666666";
  GreyScale["lightDark"] = "#4C4C4C";
  GreyScale["dark"] = "#4d4d4d";
  GreyScale["darker"] = "#404040";
  GreyScale["darkerDark"] = "#707070";
  GreyScale["darkerer"] = "#333333";
  GreyScale["darkest"] = "#000000";
})(GreyScale = exports.GreyScale || (exports.GreyScale = {}));

var RedScale;

(function (RedScale) {
  RedScale["lightest"] = "#fde7e8";
  RedScale["regular"] = "#ed1c24";
})(RedScale = exports.RedScale || (exports.RedScale = {}));

var YellowScale;

(function (YellowScale) {
  YellowScale["regular"] = "#ff8000";
})(YellowScale = exports.YellowScale || (exports.YellowScale = {}));

var AlphaScale;

(function (AlphaScale) {
  AlphaScale["medium"] = "88";
})(AlphaScale = exports.AlphaScale || (exports.AlphaScale = {}));

var FontWeight;

(function (FontWeight) {
  FontWeight["light"] = "300";
  FontWeight["regular"] = "400";
  FontWeight["medium"] = "500";
  FontWeight["semiBold"] = "600";
  FontWeight["bold"] = "700";
})(FontWeight = exports.FontWeight || (exports.FontWeight = {}));

var FontSize;

(function (FontSize) {
  FontSize[FontSize["ultra"] = 36] = "ultra";
  FontSize[FontSize["mega"] = 34] = "mega";
  FontSize[FontSize["largest"] = 32] = "largest";
  FontSize[FontSize["larger"] = 30] = "larger";
  FontSize[FontSize["large"] = 28] = "large";
  FontSize[FontSize["regular"] = 26] = "regular";
  FontSize[FontSize["small"] = 24] = "small";
  FontSize[FontSize["smaller"] = 22] = "smaller";
  FontSize[FontSize["smallest"] = 20] = "smallest";
  FontSize[FontSize["superUltra"] = 42] = "superUltra";
  FontSize[FontSize["ultraSmall"] = 18] = "ultraSmall";
  FontSize[FontSize["ultraLarge"] = 40] = "ultraLarge";
})(FontSize = exports.FontSize || (exports.FontSize = {}));

exports.FontFamily = {
  default: react_native_1.Platform.select({
    ios: 'Roboto, sans-serif',
    android: 'Roboto, sans-serif',
    web: 'Roboto, sans-serif'
  }) || 'Roboto, sans-serif'
};
exports.DefaultDarkFont = {
  color: GreyScale.darkerer,
  fontFamily: exports.FontFamily.default,
  fontSize: FontSize.smaller,
  fontWeight: FontWeight.regular
};
var FontStyleNames;

(function (FontStyleNames) {
  FontStyleNames["DefaultDarkFont"] = "DefaultDarkFont";
  FontStyleNames["DefaultLightFont"] = "DefaultLightFont";
})(FontStyleNames = exports.FontStyleNames || (exports.FontStyleNames = {}));

exports.FontStyles = {
  DefaultDarkFont: exports.DefaultDarkFont,
  DefaultLightFont: __assign(__assign({}, exports.DefaultDarkFont), {
    color: GreyScale.lightest
  }),
  DarkMediumFont: __assign(__assign({}, exports.DefaultDarkFont), {
    fontWeight: FontWeight.medium
  }),
  DarkSemiBoldFont: __assign(__assign({}, exports.DefaultDarkFont), {
    fontWeight: FontWeight.semiBold
  }),
  DarkBoldFont: __assign(__assign({}, exports.DefaultDarkFont), {
    fontWeight: FontWeight.bold
  })
};
exports.PrimaryButtonStyle = {
  alignItems: 'center',
  alignSelf: 'center',
  backgroundColor: PurpleScale.darkest,
  flexDirection: 'row',
  flexGrow: 0,
  height: 65,
  justifyContent: 'center',
  marginBottom: 40,
  maxWidth: '100%',
  width: 470
};

exports.getDimension = function (dimensionSize, dimensionType, dimensionScale) {
  var localDimensions = exports.LocalDimensions[dimensionType];
  var dimension = localDimensions > dimensionSize ? dimensionSize : localDimensions;
  return dimension * (dimensionScale || 1);
};