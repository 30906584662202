"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var DrugCodesFormatter = function () {
  function DrugCodesFormatter() {}

  DrugCodesFormatter.formatCodes = function (multiSourceCode, brandNameCode) {
    return (multiSourceCode || '?') + "/" + (brandNameCode || '?');
  };

  return DrugCodesFormatter;
}();

exports.DrugCodesFormatter = DrugCodesFormatter;