"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var panelHeadingTextStyle = {};
var panelViewStyle = {
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginBottom: '1.0rem',
  paddingBottom: '0.5rem',
  width: '100%'
};
exports.panelStyle = {
  panelHeadingTextStyle: panelHeadingTextStyle,
  panelViewStyle: panelViewStyle
};